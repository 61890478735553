import { call, put, take } from "redux-saga/effects";
import * as api from "./api";
import { subscriptionActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrint } from "../logging/logger";
import { organizationActions } from "../organization/actions";
import { session } from "../login/actions";

export const subscriptionSagas = {
  *redeemProductCoupon(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        code,
        redeemType = null,
        onCompletion = () => {},
        onError = () => {},
      } = action.payload;

      const response = yield call(api.redeemProductCoupon, { organizationId, code, redeemType });

      if (response.data.result === "success") {
        yield put(organizationActions.getCurrentSubscription.request({ organizationId }));
        yield take([
          organizationActions.getCurrentSubscription.success,
          organizationActions.getCurrentSubscription.error,
        ]);
        yield put(session.loginInfo.request());
        yield take([session.loginInfo.success, session.loginInfo.error]);
        yield put(subscriptionActions.redeemProductCoupon.success(response.data));
        onCompletion(response.data);
      } else {
        yield put(subscriptionActions.redeemProductCoupon.error());
        onError("invalid_code");
      }
    } catch (error) {
      debugPrint(error);
      yield put(subscriptionActions.redeemProductCoupon.error(error));

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },
};
