import moment from "moment";
import * as backendService from "../backendService";
import { fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const list = async () => {
  const url = `/currencies`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const convert = async ({ date, from, to }) => {
  const isoDate = moment(date).format("YYYY-MM-DD");
  const url = `/currencies/convert?from=${from}&to=${to}&date=${isoDate}`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};
