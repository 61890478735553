import { call, put } from "redux-saga/effects";
import { get, get as safeGet } from "lodash";
import { push } from "redux-first-history";
import * as api from "./api";
import * as actions from "./actions";
import * as tokenService from "../tokenService";
import * as sessionService from "../sessionService";
import { logout } from "../logoutService";
import { getCurrentOrganizationId, setCurrentOrganizationId } from "../organizationService";
import { logAnalyticsEvent } from "../analytics/analyticsService";
import { debugPrint } from "../logging/logger";
import {
  autoDetectUserLocalePropertiesIfNeeded,
  setupLanguageAndRegionIfNeeded,
} from "../locales/sagas";

export const session = {
  *login(action) {
    try {
      const { email, password, redirectUrl, onCompletion = () => {} } = action.payload;

      yield call(sessionService.remove);
      yield call(tokenService.remove);

      const response = yield call(api.login, { email, password });

      yield call(tokenService.set, response.data.token);
      yield put(actions.session.login.success());

      logAnalyticsEvent("login_success");
      logAnalyticsEvent("login_with_password");

      const redirectTo = redirectUrl || "/dashboard";
      yield put(push(redirectTo));

      onCompletion(response);
    } catch (error) {
      const { status } = safeGet(error, "response", { status: 0 });

      if (status === 403) {
        // Forbidden
        yield put(actions.session.login.error({ code: "invalid_credentials" }));
      } else if (status === 429) {
        // Too many requests
        yield put(actions.session.login.error({ code: "rate_limited" }));
      }

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },

  *loginOAuth(action) {
    try {
      const {
        provider,
        credential,
        userInvitationId,
        redirectUrl,
        registerRef,
        glParam,
        gclidParam,
        onCompletion = () => {},
      } = action.payload;

      yield call(sessionService.remove);
      yield call(tokenService.remove);

      const response = yield call(api.loginOAuth, {
        provider,
        credential,
        userInvitationId,
        registerRef,
        glParam,
        gclidParam,
      });

      yield call(tokenService.set, response.data.token);
      yield put(actions.session.login.oauth.success(response));

      if (response.data.registeredNewAccount) {
        logAnalyticsEvent("register_success");
        logAnalyticsEvent(`register_with_${provider}`);
      } else {
        logAnalyticsEvent("login_success");
        logAnalyticsEvent(`login_with_${provider}`);
      }

      const redirectTo = redirectUrl || "/dashboard";
      yield put(push(redirectTo));

      onCompletion(response);
    } catch (error) {
      yield put(actions.session.login.oauth.error(error));
    }
  },

  *loginInfo() {
    try {
      const response = yield call(api.loginInfo);
      let currentOrganizationId = getCurrentOrganizationId();

      const { user, organizations } = response.data;

      if (organizations.length < 2) {
        currentOrganizationId = get(organizations, "[0].id", null);
        setCurrentOrganizationId(currentOrganizationId);
      }

      const payload = {
        user,
        organizations,
        currentOrganization:
          response.data.organizations.find((o) => o.id === currentOrganizationId) || null,
      };

      yield put(actions.session.loginInfo.success(payload));

      yield call(setupLanguageAndRegionIfNeeded, payload.user);
      yield call(autoDetectUserLocalePropertiesIfNeeded, payload.user);
    } catch (error) {
      if (get(error, "response.status") === 403) {
        yield put(actions.session.logout.request());
      }

      debugPrint(error);
      yield put(actions.session.loginInfo.error());
    }
  },

  *logout() {
    yield call(logout);
    yield put(actions.session.logout.success());

    logAnalyticsEvent("logout_success");

    // Do a hard replace/refresh to ensure destruction of redux state
    // and its history.
    window.location.replace("/");
  },

  *expired() {
    yield call(sessionService.remove);
    yield call(tokenService.remove);

    logAnalyticsEvent("session_expired");

    // Do a hard replace/refresh to ensure destruction of redux state
    // and its history.
    window.location.replace("/");
  },

  organization: {
    *pick(action) {
      const { organizationId } = action.payload;
      setCurrentOrganizationId(organizationId);
      yield put(actions.session.organization.pick.success({ organizationId }));

      logAnalyticsEvent("organization_pick");

      window.location.reload();
    },
  },
};
