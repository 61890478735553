import { call, delay, put } from "redux-saga/effects";
import * as api from "./api";

import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { bankStatementActions } from "./actions";
import { debugPrintAndLogError } from "../logging/logger";

const bankStatementSagas = {
  *getTaskByDocumentId(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentId,
        onCompletion = () => {},
      } = action.payload;
      const response = yield call(api.getTaskByDocumentId, { organizationId, documentId });
      yield put(bankStatementActions.extract.getTask.byDocumentId.success(response.data));

      onCompletion(response.data);
    } catch (error) {
      yield put(bankStatementActions.extract.getTask.byDocumentId.error(error));
      debugPrintAndLogError(error);
    }
  },
  *listTasks(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload;
      const response = yield call(api.listTasks, { organizationId });
      yield put(bankStatementActions.listTasks.success(response.data));
    } catch (error) {
      yield put(bankStatementActions.listTasks.error(error));
      debugPrintAndLogError(error);
    }
  },
  *getExtractQuota(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload || {};
      const response = yield call(api.getExtractQuota, { organizationId });
      yield put(bankStatementActions.extract.quota.success(response.data));
    } catch (error) {
      yield put(bankStatementActions.extract.quota.error(error));
      debugPrintAndLogError(error);
    }
  },
  *extractBegin(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentId,
        onCompletion = () => {},
        onExtractCompletion = () => {},
      } = action.payload;
      const response = yield call(api.beginExtract, { organizationId, documentId });
      yield put(bankStatementActions.extract.begin.success(response.data));

      const { taskId } = response.data;

      onCompletion(response.data);

      yield call(monitorExtractStatus, { organizationId, taskId });

      onExtractCompletion(taskId);
    } catch (error) {
      yield put(bankStatementActions.extract.begin.error(error));
      debugPrintAndLogError(error);
    }
  },
  *extractStatus(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), taskId } = action.payload;
      const response = yield call(api.extractStatus, { organizationId, taskId });
      yield put(bankStatementActions.extract.status.success(response.data));
    } catch (error) {
      yield put(bankStatementActions.extract.status.error(error));
      debugPrintAndLogError(error);
    }
  },
  *extractStatusPoll(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), taskId } = action.payload;
      yield call(monitorExtractStatus, { organizationId, taskId });
      yield put(bankStatementActions.extract.status.poll.success());
    } catch (error) {
      yield put(bankStatementActions.extract.status.poll.error(error));
      debugPrintAndLogError(error);
    }
  },
};

const monitoredTasks = [];

function* monitorExtractStatus({ organizationId, taskId }) {
  try {
    if (monitoredTasks.includes(taskId)) {
      return;
    }
    monitoredTasks.push(taskId);

    let response = yield call(api.extractStatus, { organizationId, taskId });

    while (!["completed", "failed"].includes(response.data.status)) {
      yield delay(3000);
      response = yield call(api.extractStatus, { organizationId, taskId });
      yield put(bankStatementActions.extract.status.success(response.data));
    }

    monitoredTasks.splice(monitoredTasks.indexOf(taskId), 1);
  } catch (error) {
    yield put(bankStatementActions.extract.status.error(error));
    debugPrintAndLogError(error);
  }
}

export { bankStatementSagas };
