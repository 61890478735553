import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const listDocumentMassUploadTasks = async ({ organizationId, page = 1, pageSize = 10 }) => {
  const url = `/organizations/${organizationId}/documents/mass-upload/tasks`;

  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      page,
      pageSize,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const createDocumentMassUploadTask = async ({
  organizationId,
  documentCategoryId = null,
}) => {
  const url = `/organizations/${organizationId}/documents/mass-upload/tasks`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      documentCategoryId,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const getDocumentMassUploadTask = async ({ organizationId, taskId }) => {
  const url = `/organizations/${organizationId}/documents/mass-upload/tasks/${taskId}`;

  const response = await backendService.get({
    url,
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroyDocumentMassUploadTask = async ({
  organizationId,
  taskId,
  trashDocuments = false,
}) => {
  const url = `/organizations/${organizationId}/documents/mass-upload/tasks/${taskId}`;

  const response = await backendService.destroy({
    url,
    data: fromCamelToSnake({
      trashDocuments,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const uploadDocumentMassUploadTaskFile = async ({ organizationId, taskId, file }) => {
  const url = `/organizations/${organizationId}/documents/mass-upload/tasks/${taskId}/upload`;

  const response = await backendService.upload({
    url,
    file,
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const beginDocumentMassUploadTask = async ({ organizationId, taskId }) => {
  const url = `/organizations/${organizationId}/documents/mass-upload/tasks/${taskId}/begin`;

  const response = await backendService.post({
    url,
  });

  return { data: fromSnakeToCamel(response.data) };
};
