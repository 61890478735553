import { createAction } from "redux-actions";

const namespaces = {
  currencies: "CURRENCIES",
};

export const currencyActions = {
  list: {
    request: createAction(`${namespaces.currencies}/LIST/REQUEST`),
    success: createAction(`${namespaces.currencies}/LIST/SUCCESS`),
    error: createAction(`${namespaces.currencies}/LIST/ERROR`),
  },
  convert: {
    request: createAction(`${namespaces.currencies}/CONVERT/REQUEST`),
    success: createAction(`${namespaces.currencies}/CONVERT/SUCCESS`),
    error: createAction(`${namespaces.currencies}/CONVERT/ERROR`),
  },
};
