import { combineReducers } from "redux";
import { keyBy, omit } from "lodash";
import { handleActions } from "redux-actions";

import { documentExportActions } from "./actions";

const initialStates = {
  collections: {
    byId: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
      error: false,
    },
    deletingIds: [],
    deletedIds: [],
  },
};

const collections = handleActions(
  {
    [documentExportActions.list.request]: (state) => ({
      ...state,
    }),
    [documentExportActions.list.success]: (state, action) => ({
      ...state,
      byId: keyBy(action.payload.documentExports, "id"),
    }),
    [documentExportActions.destroy.success]: (state, action) => ({
      ...state,
      byId: omit(state.byId, [action.payload.id]),
    }),
    [documentExportActions.create.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: action.payload,
      },
    }),
    [documentExportActions.status.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.documentExport.id]: action.payload.documentExport,
      },
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [documentExportActions.list.request]: (state) => ({
      ...state,
      list: { ...state.list, loading: true },
    }),
    [documentExportActions.list.success]: (state) => ({
      ...state,
      list: { ...state.list, loading: false, loaded: true },
    }),
    [documentExportActions.list.error]: (state) => ({
      ...state,
      list: { ...state.list, loading: false, loaded: false, error: true },
    }),
    [documentExportActions.destroy.request]: (state, action) => ({
      ...state,
      deletingIds: [...state.deletingIds, action.payload.documentExportId],
    }),
    [documentExportActions.destroy.success]: (state, action) => ({
      ...state,
      deletingIds: state.deletingIds.filter((id) => id !== action.payload.id),
      deletedIds: [...state.deletedIds, action.payload.id],
    }),
    [documentExportActions.destroy.error]: (state, action) => ({
      ...state,
      deletingIds: state.deletingIds.filter((id) => id !== action.payload.documentExportId),
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
