import { call, put } from "redux-saga/effects";
import { useCurrentOrganizationId } from "../../../utils/sagaUtils";
import { organizationAutomationApi } from "../api";
import { organizationAutomationActions } from "../actions";
import { debugPrintAndLogError } from "../../logging/logger";

export const recurringDocumentAutomationSagas = {
  *list(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), onCompletion = () => {} } =
        action.payload || {};

      const result = yield call(organizationAutomationApi.recurringDocumentAutomation.list, {
        organizationId,
        onCompletion,
      });

      onCompletion(result.data);

      yield put(
        organizationAutomationActions.recurringDocumentAutomations.list.success(result.data),
      );
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(organizationAutomationActions.recurringDocumentAutomations.list.error(error));
    }
  },
  *get(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
      } = action.payload;

      const result = yield call(organizationAutomationApi.recurringDocumentAutomation.get, {
        organizationId,
        id,
        onCompletion,
      });

      onCompletion(result.data);

      yield put(
        organizationAutomationActions.recurringDocumentAutomations.get.success(result.data),
      );
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(organizationAutomationActions.recurringDocumentAutomations.get.error(error));
    }
  },
  *create(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        params,
        onCompletion = () => {},
      } = action.payload;

      const result = yield call(organizationAutomationApi.recurringDocumentAutomation.create, {
        organizationId,
        params,
        onCompletion,
      });

      onCompletion(result.data);

      yield put(
        organizationAutomationActions.recurringDocumentAutomations.create.success(result.data),
      );
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(organizationAutomationActions.recurringDocumentAutomations.create.error(error));
    }
  },
  *update(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        params,
        onCompletion = () => {},
      } = action.payload;

      const result = yield call(organizationAutomationApi.recurringDocumentAutomation.update, {
        organizationId,
        id,
        params,
        onCompletion,
      });

      onCompletion(result.data);

      yield put(
        organizationAutomationActions.recurringDocumentAutomations.update.success(result.data),
      );
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(organizationAutomationActions.recurringDocumentAutomations.update.error(error));
    }
  },
  *destroy(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
      } = action.payload;

      const result = yield call(organizationAutomationApi.recurringDocumentAutomation.destroy, {
        organizationId,
        id,
        onCompletion,
      });

      onCompletion(result.data);

      yield put(
        organizationAutomationActions.recurringDocumentAutomations.destroy.success(result.data),
      );
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(organizationAutomationActions.recurringDocumentAutomations.destroy.error(error));
    }
  },
};
