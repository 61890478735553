import * as backendService from "../../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../../utils/letterCasingUtils";

export const documentAssignAutomationApi = {
  list: async ({ organizationId }) => {
    const url = `/organizations/${organizationId}/automations/document-assign-automations`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  get: async ({ organizationId, id }) => {
    const url = `/organizations/${organizationId}/automations/document-assign-automations/${id}`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  create: async ({ organizationId, params }) => {
    const url = `/organizations/${organizationId}/automations/document-assign-automations`;
    const response = await backendService.post({
      url,
      data: fromCamelToSnake({ ...params }),
    });

    return { data: fromSnakeToCamel(response.data) };
  },

  update: async ({ organizationId, id, params }) => {
    const url = `/organizations/${organizationId}/automations/document-assign-automations/${id}`;

    const response = await backendService.patch({
      url,
      data: fromCamelToSnake({ ...params }),
    });

    return { data: fromSnakeToCamel(response.data) };
  },

  destroy: async ({ organizationId, id }) => {
    const url = `/organizations/${organizationId}/automations/document-assign-automations/${id}`;
    const response = await backendService.destroy({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};
