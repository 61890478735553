import { call, put } from "redux-saga/effects";
import { appConfigurationsApi as api } from "./api";
import { appConfigurationActions } from "./actions";
import { debugPrintAndLogError } from "../logging/logger";

export const appConfigurationSagas = {
  *get() {
    try {
      const response = yield call(api.get);
      yield put(appConfigurationActions.get.success(response.data));
    } catch (error) {
      yield put(appConfigurationActions.get.error(error));
      debugPrintAndLogError(error);
    }
  },
};
