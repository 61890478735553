import { call, delay, select } from "redux-saga/effects";
import { debugPrint } from "../services/logging/logger";

export function* useCurrentOrganizationId() {
  const state = yield select();
  const { currentOrganization } = state.login.collections.loginInfo;

  return currentOrganization.id;
}

export function* withRetry(saga, { maxRetries = 3, delayMs = 1000, onError = null } = {}) {
  let retries = maxRetries;
  let error = null;

  while (retries > 0) {
    try {
      yield call(saga);
      return; // if saga succeeds, exit function
    } catch (e) {
      retries -= 1;
      error = e;
      if (retries > 0) {
        yield delay(delayMs); // wait before retrying
      }
    }
  }

  // If all retries fail, call onError if it exists
  if (typeof onError === "function") {
    yield call(onError, error);
  } else {
    debugPrint("Saga failed after retries:", error);
  }
}
