import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { omit } from "lodash";
import { organizationWebhookActions } from "./actions";

const initialStates = {
  collections: {
    byId: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
    update: {
      loading: false,
      loaded: false,
    },
    destroy: {
      loading: false,
      loaded: false,
    },
  },
};

const collections = handleActions(
  {
    [organizationWebhookActions.list.success]: (state, action) => ({
      ...state,
      byId: action.payload.organizationWebhooks.reduce(
        (acc, organizationWebhook) => ({
          ...acc,
          [organizationWebhook.id]: organizationWebhook,
        }),
        {},
      ),
    }),
    [organizationWebhookActions.create.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.organizationWebhook.id]: action.payload.organizationWebhook,
      },
    }),
    [organizationWebhookActions.update.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.organizationWebhook.id]: action.payload.organizationWebhook,
      },
    }),
    [organizationWebhookActions.destroy.success]: (state, action) => ({
      ...state,
      byId: omit(state.byId, action.payload.id),
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [organizationWebhookActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [organizationWebhookActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [organizationWebhookActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
    [organizationWebhookActions.create.request]: (state) => ({
      ...state,
      create: { loading: true, loaded: false },
    }),
    [organizationWebhookActions.create.success]: (state) => ({
      ...state,
      create: { loading: false, loaded: true },
    }),
    [organizationWebhookActions.create.error]: (state) => ({
      ...state,
      create: { loading: false, loaded: false, error: true },
    }),
    [organizationWebhookActions.update.request]: (state) => ({
      ...state,
      update: { loading: true, loaded: false },
    }),
    [organizationWebhookActions.update.success]: (state) => ({
      ...state,
      update: { loading: false, loaded: true },
    }),
    [organizationWebhookActions.update.error]: (state) => ({
      ...state,
      update: { loading: false, loaded: false, error: true },
    }),
    [organizationWebhookActions.destroy.request]: (state) => ({
      ...state,
      destroy: { loading: true, loaded: false },
    }),
    [organizationWebhookActions.destroy.success]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: true },
    }),
    [organizationWebhookActions.destroy.error]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
