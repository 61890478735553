import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { bankStatementActions } from "./actions";
import { transactionLineCollectionActions } from "../transaction-line-collections/actions";

const initialStates = {
  collections: {
    tasks: {
      all: [],
    },
    quota: null,
  },
  status: {
    quota: {
      loading: false,
      loaded: false,
    },
    tasks: {
      list: {
        loading: false,
        loaded: false,
      },
    },
  },
};

function replacingTask(all, task) {
  if (!task) {
    return [...all];
  }

  if (all.find((t) => t.id === task.id)) {
    return all.map((t) => (t.id === task.id ? task : t));
  }

  return [...all, task];
}

const collections = handleActions(
  {
    [bankStatementActions.extract.list.success]: (state, action) => ({
      ...state,
      tasks: {
        ...state.tasks,
        all: action.payload.tasks,
      },
    }),
    [bankStatementActions.extract.begin.success]: (state, action) => ({
      ...state,
      tasks: {
        ...state.tasks,
        all: replacingTask(state.tasks.all, action.payload.task),
      },
    }),
    [bankStatementActions.extract.status.success]: (state, action) => ({
      ...state,
      tasks: {
        ...state.tasks,
        all: replacingTask(state.tasks.all, action.payload.task),
      },
    }),
    [bankStatementActions.extract.getTask.byDocumentId.success]: (state, action) => ({
      ...state,
      tasks: {
        ...state.tasks,
        all: replacingTask(state.tasks.all, action.payload.task),
      },
    }),
    [transactionLineCollectionActions.destroy.success]: (state, action) => ({
      ...state,
      tasks: {
        ...state.tasks,
        all: state.tasks.all.filter((t) => t.transactionLineCollectionId !== action.payload.id),
      },
    }),
    [bankStatementActions.extract.quota.success]: (state, action) => ({
      ...state,
      quota: action.payload,
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [bankStatementActions.extract.list.request]: (state) => ({
      ...state,
      tasks: {
        ...state.tasks,
        list: { loading: true, loaded: false },
      },
    }),
    [bankStatementActions.extract.list.success]: (state) => ({
      ...state,
      tasks: {
        ...state.tasks,
        list: { loading: false, loaded: true, error: false },
      },
    }),
    [bankStatementActions.extract.list.error]: (state) => ({
      ...state,
      tasks: {
        ...state.tasks,
        list: { loading: false, loaded: true, error: true },
      },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
