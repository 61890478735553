import * as backendService from "../backendService";
import { fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const appConfigurationsApi = {
  get: async () => {
    const url = `/config/app`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};
