import React from "react";
import PropTypes from "prop-types";
import { useCurrentOrganization } from "../../services/login/utils";
import Button from "../button/Button";
import { showProFeatureModal } from "./pro-feature-modal/ProFeatureModal";
import { useDocumentCountCreatedInMonth } from "../../services/documents/hooks";

export const quotaDepletingInputSources = [
  "web",
  "mobile_ios",
  "mobile_android",
  "inbound_email",
  "web_mass_upload",
];

export const composeOrganizationQuotaCountFilter = () => {
  const now = new Date();

  return {
    createdMonth: now.getMonth() + 1,
    createdYear: now.getFullYear(),
    inputSources: quotaDepletingInputSources,
  };
};

export const useIsOrganizationWithinQuota = () => {
  const currentOrganization = useCurrentOrganization();

  if (!currentOrganization) {
    return true;
  }

  const { documentQuota, hasPaidSubscription } = currentOrganization;

  if (hasPaidSubscription) {
    return true;
  }

  const { count, isLoaded } = useDocumentCountCreatedInMonth(composeOrganizationQuotaCountFilter());

  return isLoaded && count < documentQuota;
};

const DocumentQuotaGuard = ({ children }) => {
  if (Array.isArray(children)) {
    throw new Error("DocumentQuotaGuard only supports one child.");
  }

  const overQuota = !useIsOrganizationWithinQuota();

  let component = children;

  if (component.type === Button && overQuota) {
    component = React.cloneElement(component, {
      onClick: () => {
        showProFeatureModal({ messageType: "quota_depleted" });
      },
    });
  }

  return component;
};

DocumentQuotaGuard.propTypes = {
  children: PropTypes.any,
};

export default DocumentQuotaGuard;
