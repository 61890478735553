import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const listAvailableSubscriptions = async ({ organizationId }) => {
  const url = "/subscriptions";
  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      organizationId,
      platform: "stripe",
    }),
  });
  return { data: fromSnakeToCamel(response.data) };
};

export const redeemProductCoupon = async ({ organizationId, code, redeemType }) => {
  const url = "/subscriptions/redeem-code";

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      organizationId,
      code,
      redeemType,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};
