import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const organizationWebhooksApi = {
  list: async ({ organizationId }) => {
    const url = `/organizations/${organizationId}/webhooks`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  create: async ({ organizationId, url: webhookUrl, enabled }) => {
    const url = `/organizations/${organizationId}/webhooks`;
    const response = await backendService.post({
      url,
      data: fromCamelToSnake({ url: webhookUrl, enabled }),
    });

    return { data: fromSnakeToCamel(response.data) };
  },

  update: async ({ organizationId, id, url: webhookUrl, enabled }) => {
    const url = `/organizations/${organizationId}/webhooks/${id}`;

    const response = await backendService.patch({
      url,
      data: fromCamelToSnake({ url: webhookUrl, enabled }),
    });

    return { data: fromSnakeToCamel(response.data) };
  },

  destroy: async ({ organizationId, id }) => {
    const url = `/organizations/${organizationId}/webhooks/${id}`;
    const response = await backendService.destroy({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  listTasks: async ({ organizationId, page = 1, pageSize = 100 }) => {
    const url = `/organizations/${organizationId}/webhooks/tasks`;
    const response = await backendService.get({ url, data: fromCamelToSnake({ page, pageSize }) });

    return { data: fromSnakeToCamel(response.data) };
  },

  getTask: async ({ organizationId, id }) => {
    const url = `/organizations/${organizationId}/webhooks/tasks/${id}`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  restartTask: async ({ organizationId, id }) => {
    const url = `/organizations/${organizationId}/webhooks/tasks/${id}/restart`;
    const response = await backendService.post({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};
