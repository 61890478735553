import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { sortBy } from "lodash";

import { currencyActions } from "./actions";

const initialStates = {
  collections: {
    all: [],
    conversions: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
  },
};

const collections = handleActions(
  {
    [currencyActions.list.success]: (state, action) => ({
      ...state,
      all: sortBy(action.payload.currencies, "label"),
    }),
    [currencyActions.convert.success]: (state, action) => ({
      ...state,
      conversions: {
        ...state.conversions,
        ...action.payload.conversion,
      },
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [currencyActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [currencyActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [currencyActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
