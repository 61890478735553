import React from "react";
import { Helmet } from "react-helmet";

import { debugPrintAndLogError } from "../logging/logger";

export const TrackAffiliateProgram = () => (
  <Helmet>
    <script
      async
      src="https://cdn.tolt.io/tolt.js"
      data-tolt="34513fe9-631c-42b4-b28d-6a44f71a949e"
    ></script>
  </Helmet>
);

export const getAffiliateProgramReferralCode = () => {
  if (!window.tolt) {
    debugPrintAndLogError("Misconfiguration!!! window.tolt is not available.");
    return null;
  }

  try {
    return window.tolt_referral;
  } catch (error) {
    debugPrintAndLogError(error);
    return null;
  }
};

export const trackAffiliateSignupOrLogin = async (emailAddress) => {
  if (!window.tolt) {
    debugPrintAndLogError("Misconfiguration!!! window.tolt is not available.");
    return;
  }
  if (!emailAddress) {
    debugPrintAndLogError("Misconfiguration!!! email address is not available.");
    return;
  }

  try {
    await window.tolt.signup(emailAddress);
  } catch (error) {
    debugPrintAndLogError(error);
  }
};
