import jwtDecode from "jwt-decode";

import storeService from "./storeService";

const TOKEN_KEY = "token";

export const decode = (token) => jwtDecode(token);

export const get = () => storeService.get(TOKEN_KEY);
export const remove = () => storeService.remove(TOKEN_KEY);
export const set = (value) => storeService.set(TOKEN_KEY, value);

let isRenewingToken = false;

export const setIsRenewingToken = (value) => { isRenewingToken = value; };
export const getIsRenewingToken = () => isRenewingToken;

// Seconds until the token expires. Returns null if token is invalid.
export const tokenLifeSeconds = (token) => {
  const currentTime = new Date().getTime() / 1000;
  const decodedToken = decode(token);

  if (!decodedToken) {
    return null;
  }

  return decodedToken.exp - currentTime;
};

export const isActive = (token) => {
  if (!token) {
    return false;
  }

  const currentTime = new Date().getTime() / 1000;
  const decodedToken = decode(token);

  if (!decodedToken) {
    return false;
  }

  return (decodedToken && (decodedToken.exp > currentTime));
};
