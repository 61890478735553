import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { getFixedT } from "i18next";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./confirm-dialog.scss";
import Button from "../button/Button";

export const showConfirmDialog = ({
  title = null,
  message = "Message",
  titleTag = "h1",
  messageTag = "p",
  customContent = null,
  cancelButton = "Cancel",
  confirmButton = "Confirm",
  cancelAction = () => {},
  confirmAction = () => {},
  isModal = false,
  className = "",
}) => {
  const TitleTag = titleTag;
  const MessageTag = messageTag;

  confirmAlert({
    closeOnClickOutside: !isModal,
    closeOnEscape: !isModal,
    customUI: ({ onClose }) => (
      <div className={`confirm-dialog ${className}`}>
        {title && <TitleTag>{title}</TitleTag>}
        <MessageTag>{message}</MessageTag>
        {customContent && <div>{customContent}</div>}
        <div className="actions">
          {cancelButton && (
            <Button
              className="button cancel-button"
              theme="subtle"
              onClick={() => {
                cancelAction();
                onClose();
              }}
            >
              {cancelButton}
            </Button>
          )}
          <Button
            theme="primary"
            className="button confirm-button"
            onClick={() => {
              confirmAction();
              onClose();
            }}
          >
            {confirmButton}
          </Button>
        </div>
      </div>
    ),
    onClickOutside: () => {
      if (!isModal) {
        cancelAction();
      }
    },
  });
};

export const showMessageDialog = ({
  title = "Title",
  message = "Message",
  titleTag = "h1",
  messageTag = "p",
  customContent = null,
  confirmButton = "Dismiss",
  confirmAction = () => {},
  isModal = false,
  className = "",
}) =>
  showConfirmDialog({
    title,
    message,
    titleTag,
    messageTag,
    customContent,
    confirmButton,
    confirmAction,
    isModal,
    className,
    cancelButton: null,
  });

export const showGenericErrorDialog = ({ confirmAction = () => {}, errorDetail = null } = {}) => {
  const t = getFixedT(null, "meta");

  showMessageDialog({
    title: t("genericError.title"),
    message: t("genericError.message"),
    customContent: errorDetail ? <p>{errorDetail}</p> : null,
    confirmAction,
  });
};
