import { call, put } from "redux-saga/effects";
import { paymentMethodAccountActions } from "./actions";
import { paymentMethodAccountsApi } from "./api";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const paymentMethodAccountSagas = {
  *list({ payload = {} }) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), onCompletion = () => {} } =
        payload || {};
      const response = yield call(paymentMethodAccountsApi.list, { organizationId });
      yield put(paymentMethodAccountActions.list.success(response.data));

      onCompletion(response.data);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(paymentMethodAccountActions.list.error(e));
    }
  },

  *create({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        onCompletion = () => {},
      } = payload;

      const response = yield call(paymentMethodAccountActions.create, {
        organizationId,
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
      });

      yield put(paymentMethodAccountActions.create.success(response.data));
      onCompletion(response.data.hashTag);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(paymentMethodAccountActions.create.error(e));
    }
  },

  *update({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        onCompletion = () => {},
      } = payload;

      const response = yield call(paymentMethodAccountsApi.update, {
        organizationId,
        id,
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
      });

      yield put(paymentMethodAccountActions.update.success(response.data));
      onCompletion(response.data.hashTag);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(paymentMethodAccountActions.update.error(e));
    }
  },

  *destroy({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
        organizationUserId,
        kind,
        lastDigits,
        accountName,
      } = payload;

      const response = yield call(paymentMethodAccountsApi.destroy, {
        organizationId,
        id,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
      });
      yield put(paymentMethodAccountActions.destroy.success(response.data));
      onCompletion(response.data);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(paymentMethodAccountActions.destroy.error(e));
    }
  },
};
