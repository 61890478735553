import { createAction } from "redux-actions";

const namespaces = {
  dashboard: "DASHBOARD",
};

export const dashboard = {
  init: {
    request: createAction(`${namespaces.dashboard}/INIT/REQUEST`),
    success: createAction(`${namespaces.dashboard}/INIT/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/INIT/ERROR`),
  },
};
