import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { uniq } from "lodash";

import { accountableActions } from "./actions";
import { documentActions } from "../documents/actions";

const initialStates = {
  collections: {
    byId: {},
    scopes: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
    create: {
      loading: false,
      loaded: false,
    },
    update: {
      loading: false,
      loaded: false,
    },
    destroy: {
      loading: false,
      loaded: false,
    },
  },
};

const composeScope = (transactionType, integrationKind) =>
  [transactionType, integrationKind].filter(Boolean).join("-") || "all";

const updatingScopes = (state) => {
  const newState = {
    ...state,
    scopes: {},
  };

  const collection = Object.values(newState.byId);

  collection.forEach((accountable) => {
    const scope = composeScope(accountable.transactionType, accountable.integrationKind);

    if (!newState.scopes[scope]) {
      newState.scopes[scope] = [];
    }

    newState.scopes[scope].push(accountable.id);
  });

  Object.keys(newState.scopes).forEach((scope) => {
    newState.scopes[scope] = uniq(newState.scopes[scope]);
  });

  return newState;
};

const updatingAccountables = (state, accountables) => {
  const newState = {
    ...state,
    byId: { ...state.byId },
  };

  if (!accountables) {
    return newState;
  }

  const multiple = Array.isArray(accountables);

  if (multiple) {
    accountables.filter(Boolean).forEach((accountable) => {
      newState.byId[accountable.id] = accountable;
    });
  } else {
    newState.byId[accountables.id] = accountables;
  }

  return updatingScopes(newState);
};

const collections = handleActions(
  {
    [accountableActions.list.success]: (state, action) => {
      const { accountables, transactionType, integrationKind } = action.payload;
      const scope = composeScope(transactionType, integrationKind);

      return {
        ...state,
        byId: accountables.reduce((memo, val) => ({ ...memo, [val.id]: val }), {}),
        scopes: {
          ...state.scopes,
          [scope]: uniq(accountables.map((a) => a.id)),
        },
      };
    },
    [documentActions.get.success]: (state, action) =>
      updatingAccountables(state, action.payload.document.accountable),
    [documentActions.getList.success]: (state, action) =>
      updatingAccountables(
        state,
        action.payload.documents.map((d) => d.accountable),
      ),
    [documentActions.update.success]: (state, action) =>
      updatingAccountables(state, action.payload.document.accountable),
    [documentActions.updateMultiple.success]: (state, action) =>
      updatingAccountables(
        state,
        action.payload.documents.map((d) => d.accountable),
      ),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [accountableActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [accountableActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [accountableActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
    [accountableActions.create.request]: (state) => ({
      ...state,
      create: { loading: true, loaded: false },
    }),
    [accountableActions.create.success]: (state) => ({
      ...state,
      create: { loading: false, loaded: true },
    }),
    [accountableActions.create.error]: (state) => ({
      ...state,
      create: { loading: false, loaded: false, error: true },
    }),
    [accountableActions.update.request]: (state) => ({
      ...state,
      update: { loading: true, loaded: false },
    }),
    [accountableActions.update.success]: (state) => ({
      ...state,
      update: { loading: false, loaded: true },
    }),
    [accountableActions.update.error]: (state) => ({
      ...state,
      update: { loading: false, loaded: false, error: true },
    }),
    [accountableActions.destroy.request]: (state) => ({
      ...state,
      destroy: { loading: true, loaded: false },
    }),
    [accountableActions.destroy.success]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: true },
    }),
    [accountableActions.destroy.error]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
