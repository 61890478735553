import { compact, get, reverse, sortBy } from "lodash";
import { useSelector } from "react-redux";
import { composeDocumentFilterScope } from "./scopes/documentScopes";

export const useScopedDocumentCollection = (scope) => {
  const collection = useSelector((state) => state.documents.collections.scoped[scope] || []);
  const loadedScopes = useSelector((state) => state.documents.status.list.loadedScopes);
  const dirtyScopes = useSelector((state) => state.documents.collections.dirtyScopes);

  const isLoaded = loadedScopes.indexOf(scope) !== -1;
  const isDirty = dirtyScopes.includes(scope);

  return [collection, isLoaded, isDirty];
};

export const useScopedDocumentsTotalValue = (scope) => {
  const collection = useSelector((state) => state.documents.collections.totalValues[scope]);
  const isDirty = useSelector((state) =>
    state.documents.collections.totalValuesDirtyScopes.includes(scope),
  );
  const isLoaded = collection !== undefined;

  return [collection, isLoaded, isDirty];
};

export const useScopedDocumentCount = (scope) => {
  const collection = useSelector((state) => state.documents.collections.counts[scope]);
  const isDirty = useSelector((state) =>
    state.documents.collections.countsDirtyScopes.includes(scope),
  );
  const isLoaded = collection !== undefined;

  return [collection, isLoaded, isDirty];
};

export const useDocument = (documentId) =>
  useSelector((state) => state.documents.collections.byId[documentId]) || null;

export const useDocuments = (documentIds) => {
  const byIds = useSelector((state) => state.documents.collections.byId);

  return documentIds.map((id) => byIds[id]).filter(Boolean);
};

export const useDocumentStatus = () => useSelector((state) => state.documents.status);

export const useUploadProgress = () => {
  const status = useDocumentStatus();
  const { uploadingFiles, uploadedFiles, errorFiles } = status.upload;

  return [uploadingFiles, uploadedFiles, errorFiles];
};

export const useDeletingDocumentFileIds = () =>
  useSelector((state) => state.documents.status.deleteFile.deletingDocumentFileIds);

export const useIsDeletingDocumentFileId = (id) =>
  useSelector((state) => state.documents.status.deleteFile.deletingDocumentFileIds).includes(id);

export const useDocumentMonths = ({ order = "desc" } = {}) => {
  const isLoaded = useSelector((state) => state.documents.status.meta.loaded);
  let documentMonths =
    useSelector((state) => state.documents.collections.meta.documentMonths) || [];

  if (order === "desc") {
    documentMonths = reverse(Array.from(documentMonths));
  }

  return [documentMonths, isLoaded];
};

export const useDocumentMonthsAfter = (month, year) => {
  const [documentMonths, isLoaded] = useDocumentMonths({ order: "asc" });

  if (!isLoaded) {
    return [];
  }

  const index = documentMonths.findIndex((m) => m.month === month && m.year === year);

  return [documentMonths.slice(index + 1), isLoaded];
};

export const useDocumentMonthsBefore = (month, year) => {
  const [documentMonths, isLoaded] = useDocumentMonths({ order: "asc" });

  if (!isLoaded) {
    return [];
  }

  const index = documentMonths.findIndex((m) => m.month === month && m.year === year);

  return [documentMonths.slice(0, index), isLoaded];
};

export const useHashTagsById = () => useSelector((state) => state.hashTags.collections.byId || {});
export const useHashTagNames = () =>
  useSelector((state) =>
    sortBy(Object.values(state.hashTags.collections.byId || {}), "name").map((h) => h.name),
  );

export const useDocumentFileProcessingStatus = (documentFileId) => {
  const processingFiles = useSelector(
    (state) => state.documents.status.processing.processingDocumentFiles,
  );

  const documentFileStatus = processingFiles.find((f) => f.id === documentFileId);

  if (!documentFileStatus) {
    return "none";
  }

  return documentFileStatus.status;
};

export const useIsDocumentUpdating = (documentId) =>
  useSelector((state) =>
    get(state, "documents.status.processing.updatingDocumentIds", []),
  ).includes(documentId);

export const useDocumentCountCreatedInMonth = ({
  createdMonth,
  createdYear,
  inputSources = null,
}) => {
  const filter = {
    createdMonth,
    createdYear,
    inputSources,
  };

  const scope = composeDocumentFilterScope(filter);

  const [count, isLoaded] = useScopedDocumentCount(scope);

  return { count, isLoaded };
};

export const useAllOcrAnalysisTasks = () => {
  const ocrAnalysisTasks = useSelector(
    (state) => state.documents.collections.ocrAnalysisTasksByDocumentFileId,
  );

  return Object.values(ocrAnalysisTasks);
};

export const useOcrAnalysisTasks = ({ documentFileIds = [] }) => {
  const ocrAnalysisTasks = useSelector(
    (state) => state.documents.collections.ocrAnalysisTasksByDocumentFileId,
  );

  return compact(documentFileIds.map((documentFileId) => ocrAnalysisTasks[documentFileId]));
};

export const useIsOcrAnalyzingDocument = (documentId) => {
  const document = useDocument(documentId);
  const ocrAnalysisTasks = useAllOcrAnalysisTasks();

  if (!document) {
    return false;
  }

  return isOcrAnalyzingDocument(document, ocrAnalysisTasks);
};

export function isOcrAnalyzingDocument(document, ocrAnalysisTasks = []) {
  const documentFileIds = (document.documentFiles || []).map((f) => f.id);
  const documentTasks = compact(
    documentFileIds.map((documentFileId) =>
      ocrAnalysisTasks.find((t) => t.documentFileId === documentFileId),
    ),
  );
  const statuses = documentTasks.map((task) => task.status);

  return statuses.includes("queued") || statuses.includes("in_progress");
}
