import { createAction } from "redux-actions";

const namespaces = {
  session: "SESSION",
};

export const session = {
  login: {
    request: createAction(`${namespaces.session}/LOGIN/REQUEST`),
    success: createAction(`${namespaces.session}/LOGIN/SUCCESS`),
    error: createAction(`${namespaces.session}/LOGIN/ERROR`),

    oauth: {
      request: createAction(`${namespaces.session}/LOGIN/OAUTH/REQUEST`),
      success: createAction(`${namespaces.session}/LOGIN/OAUTH/SUCCESS`),
      error: createAction(`${namespaces.session}/LOGIN/OAUTH/ERROR`),
    },
  },
  loginInfo: {
    request: createAction(`${namespaces.session}/LOGIN-INFO/REQUEST`),
    refresh: createAction(`${namespaces.session}/LOGIN-INFO/REFRESH`),
    success: createAction(`${namespaces.session}/LOGIN-INFO/SUCCESS`),
    error: createAction(`${namespaces.session}/LOGIN-INFO/ERROR`),
  },
  organization: {
    pick: {
      request: createAction(`${namespaces.session}/ORGANIZATION/PICK/REQUEST`),
      success: createAction(`${namespaces.session}/ORGANIZATION/PICK/SUCCESS`),
      errro: createAction(`${namespaces.session}/ORGANIZATION/PICK/ERROR`),
    },
  },
  logout: {
    request: createAction(`${namespaces.session}/LOGOUT/REQUEST`),
    success: createAction(`${namespaces.session}/LOGOUT/SUCCESS`),
  },
  redirect: {
    login: createAction(`${namespaces.session}/REDIRECT/LOGIN`),
  },
};
