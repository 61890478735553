import { all, call, put, take } from "redux-saga/effects";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";
import { organizationAutomationActions } from "./actions";
import { documentAssignAutomationSagas } from "./sagas/documentAssignAutomationSagas";
import { recurringDocumentAutomationSagas } from "./sagas/recurringDocumentAutomationSagas";

export const organizationAutomationSagas = {
  documentAssignAutomation: documentAssignAutomationSagas,
  recurringDocumentAutomation: recurringDocumentAutomationSagas,

  *list(action) {
    try {
      const { organizationId = yield call(useCurrentOrganizationId), onCompletion = () => {} } =
        action.payload || {};

      yield put(
        organizationAutomationActions.recurringDocumentAutomations.list.request({
          organizationId,
        }),
      );
      yield put(
        organizationAutomationActions.documentAssignAutomations.list.request({
          organizationId,
        }),
      );

      // Use `all` to wait for both actions to either succeed or fail
      const [recurringResult, assignResult] = yield all([
        take([
          organizationAutomationActions.recurringDocumentAutomations.list.success,
          organizationAutomationActions.recurringDocumentAutomations.list.error,
        ]),
        take([
          organizationAutomationActions.documentAssignAutomations.list.success,
          organizationAutomationActions.documentAssignAutomations.list.error,
        ]),
      ]);

      // Optional: Check if both actions are successful to proceed or handle errors
      if (
        recurringResult.type ===
          organizationAutomationActions.recurringDocumentAutomations.list.success().type &&
        assignResult.type ===
          organizationAutomationActions.documentAssignAutomations.list.success().type
      ) {
        yield put(
          organizationAutomationActions.list.success({
            recurringDocumentAutomations: recurringResult.payload.recurringDocumentAutomations,
            documentAssignAutomations: assignResult.payload.documentAssignAutomations,
          }),
        );
      } else {
        // Handle errors accordingly
        throw new Error("Error in one of the automations list operations");
      }

      onCompletion();
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(organizationAutomationActions.list.error(error));
    }
  },
};
