import * as backendService from "../backendService";
import { fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const getTaskByDocumentId = async ({ organizationId, documentId }) => {
  const url = `/organizations/${organizationId}/bank-statements/extract-tasks/by-document/${documentId}`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const listTasks = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/bank-statements/extract-tasks`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const beginExtract = async ({ organizationId, documentId }) => {
  const url = `/organizations/${organizationId}/bank-statements/${documentId}/begin-extract`;
  const response = await backendService.post({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const extractStatus = async ({ organizationId, taskId }) => {
  const url = `/organizations/${organizationId}/bank-statements/extract-status/${taskId}`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const getExtractQuota = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/bank-statements/extract-quota`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};
