import { compact, omit, uniq, uniqBy } from "lodash";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import moment from "moment";

import { documentActions } from "./actions";
import {
  composeDocumentFilterScope,
  documentMatchesScope,
  extractFilterFromScope,
} from "./scopes/documentScopes";
import { sortDocumentMonths, fillDocumentMonthBlanks } from "./utils";
import { transactionLineCollectionActions } from "../transaction-line-collections/actions";
import { documentMassUploadActions } from "../document-mass-uploads/actions";

const initialStates = {
  collections: {
    scoped: {}, // Scope key -> [Document]
    totalValues: {}, // Scope key -> [DocumentTotalValue]
    counts: {}, // Scope key -> count
    dirtyScopes: [],
    countsDirtyScopes: [],
    totalValuesDirtyScopes: [],
    byId: {},
    meta: {
      documentMonths: [],
    },
    hashTags: [],
    hashTagsById: {},
    ocrAnalysisTasksByDocumentFileId: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
      loadingScopes: [],
      loadedScopes: [],
    },
    meta: {
      loading: true,
      loaded: false,
      error: false,
    },
    get: {
      loadingIds: [],
      loadedIds: [],
      errorIds: [],
    },
    create: {
      creating: false,
      created: true,
      error: false,
    },
    upload: {
      uploadingFiles: [],
      uploadedFiles: [],
      errorFiles: [],
    },
    deleteFile: {
      deletingDocumentFileIds: [],
      deletedDocumentFileIds: [],
      errorDeletingDocumentFileIds: [],
    },
    processing: {
      processingDocumentFiles: [], // [{ id: String, status: "processing" || "uploading" || "uploaded" }]
      updatingDocumentIds: [], // [String]
    },
  },
};

const updatingDocumentMonthsIfNeeded = (state, action) => {
  const { document } = action.payload;
  const { documentMonths } = state.meta;

  const month = moment(document.documentTime).get("month") + 1;
  const year = moment(document.documentTime).get("year");

  const currentMonth = moment().get("month") + 1;
  const currentYear = moment().get("year");

  const hasMonth = !!documentMonths.find((m) => m.year === year && m.month === month);

  if (hasMonth) {
    return { ...state };
  }

  return {
    ...state,
    meta: {
      ...state.meta,
      documentMonths: fillDocumentMonthBlanks(
        sortDocumentMonths([
          ...state.meta.documentMonths,
          { month, year, isCurrent: month === currentMonth && year === currentYear },
        ]),
      ),
    },
  };
};

const removingDocumentsFromScopedCollections = (collections, documentIds) => {
  const scoped = { ...collections.scoped };

  Object.keys(scoped).forEach((scope) => {
    if (scoped[scope].find((d) => documentIds.includes(d.id))) {
      scoped[scope] = scoped[scope].filter((d) => !documentIds.includes(d.id));
    }
  });

  return scoped;
};

const restoringTrashedDocumentFromScopedCollections = (collections, documentIds) => {
  const scoped = { ...collections.scoped };

  Object.keys(scoped).forEach((scope) => {
    const document = scoped[scope].find((d) => documentIds.includes(d.id));

    if (!document) {
      return;
    }

    const filter = extractFilterFromScope(scope);
    const isTrashedScope = filter.trashed === true;

    if (isTrashedScope) {
      // Remove from trashed scope
      scoped[scope] = scoped[scope].filter((d) => !documentIds.includes(d.id));
    } else {
      // Restore to trashed scope
      scoped[scope] = uniqBy([...scoped[scope], document], "id");
    }
  });

  return scoped;
};

const updatedScopedCollections = (state, document) => {
  const newScoped = { ...state.scoped };
  const keys = Object.keys(newScoped);

  keys.forEach((key) => {
    const collection = newScoped[key];
    const index = collection.findIndex((d) => d.id === document.id);
    const matchesScope = documentMatchesScope(document, key);

    if (index >= 0) {
      if (matchesScope) {
        collection.splice(index, 1, { ...document });
      } else {
        collection.splice(index, 1);
      }
    } else if (matchesScope) {
      collection.push(document);
    }
  });

  return newScoped;
};

const updatingDocument = (state, action) => {
  const { document } = action.payload;
  const oldDocument = state.byId[document.id];

  const getDirtyScopes = (data, d) =>
    Object.keys(data).filter((scope) => d && documentMatchesScope(d, scope));

  const dirtyScopes =
    getDirtyScopes(state.scoped, document) || getDirtyScopes(state.counts, oldDocument);
  const countsDirtyScopes =
    getDirtyScopes(state.counts, document) || getDirtyScopes(state.counts, oldDocument);
  const totalValuesDirtyScopes =
    getDirtyScopes(state.totalValues, document) || getDirtyScopes(state.counts, oldDocument);

  return updatingDocumentMonthsIfNeeded(
    {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.document.id]: document,
      },
      scoped: updatedScopedCollections(state, document),
      dirtyScopes,
      countsDirtyScopes,
      totalValuesDirtyScopes,
      hashTags: uniq([...state.hashTags, ...action.payload.document.hashTags.map((t) => t.name)]),
    },
    action,
  );
};

const updatingDocuments = (state, action) => {
  const { documents, restoreAction = false } = action.payload;

  let result = { ...state };

  documents.forEach((document) => {
    result = updatingDocument(result, { payload: { document } });
  });

  if (restoreAction) {
    result = {
      ...result,
      scoped: restoringTrashedDocumentFromScopedCollections(
        result,
        documents.map((d) => d.id),
      ),
    };
  }

  return result;
};

const collections = handleActions(
  {
    [documentActions.list.request]: (state, action) => {
      const { filter = null } = action.payload;

      if (!filter) {
        throw new Error("A filter is required");
      }

      return {
        ...state,
        scoped: {
          ...state.scoped,
        },
      };
    },
    [documentActions.list.success]: (state, action) => {
      const { filter = null } = action.payload;

      if (!filter) {
        throw new Error("A filter is required");
      }

      const scope = composeDocumentFilterScope(filter);

      return {
        ...state,
        scoped: {
          ...state.scoped,
          [scope]: action.payload.documents,
        },
        dirtyScopes: state.dirtyScopes.filter((s) => s !== scope),
        byId: {
          ...state.byId,
          ...action.payload.documents.reduce((acc, document) => {
            acc[document.id] = document;
            return acc;
          }, {}),
        },
      };
    },
    [documentActions.count.success]: (state, action) => {
      const scope = composeDocumentFilterScope(action.payload.filter);

      return {
        ...state,
        counts: {
          ...state.counts,
          [scope]: action.payload.count,
        },
        countsDirtyScopes: state.countsDirtyScopes.filter((s) => s !== scope),
      };
    },
    [documentActions.totalValue.success]: (state, action) => {
      const scope = composeDocumentFilterScope(action.payload.filter);

      return {
        ...state,
        totalValues: {
          ...state.totalValues,
          [scope]: action.payload.totalValue,
        },
      };
    },
    [documentActions.listMeta.success]: (state, action) => ({
      ...state,
      meta: action.payload.meta,
    }),
    [documentActions.get.success]: (state, action) => ({
      ...updatingDocument(state, action),
    }),
    [documentActions.getList.success]: (state, action) => ({
      ...updatingDocuments(state, action),
    }),
    [documentActions.search.success]: (state, action) => ({
      ...updatingDocuments(state, action),
    }),
    [documentMassUploadActions.get.success]: (state, action) => ({
      ...updatingDocuments(state, {
        payload: {
          documents: compact(action.payload.task.documentUploadTasks.map((t) => t.document)),
        },
      }),
    }),
    [documentActions.create.success]: (state, action) => ({
      ...updatingDocument(state, action),
    }),
    [documentActions.update.success]: (state, action) => ({
      ...updatingDocument(state, action),
    }),
    [documentActions.updateMultiple.success]: (state, action) => ({
      ...updatingDocuments(state, action),
    }),
    [documentActions.upload.success]: (state, action) => ({
      ...updatingDocument(state, action),
    }),
    [documentActions.replaceFile.success]: (state, action) => ({
      ...updatingDocument(state, action),
    }),
    [documentActions.uploadProcessedFile.success]: (state, action) => ({
      ...updatingDocument(state, action),
    }),
    [documentActions.deleteFile.success]: (state, action) => {
      const { documentId, id } = action.payload;

      if (!state.byId[documentId]) {
        return { ...state };
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [documentId]: {
            ...state.byId[documentId],
            documentFiles: state.byId[documentId].documentFiles.filter((df) => df.id !== id),
          },
        },
      };
    },
    [documentActions.moveToOrganization.success]: (state, action) => ({
      ...state,
      byId: omit(state.byId, action.payload.document.id),
      scoped: removingDocumentsFromScopedCollections(state, [action.payload.document.id]),
    }),
    [documentActions.trash.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.document.id]: action.payload.document,
      },
      scoped: removingDocumentsFromScopedCollections(state, [action.payload.document.id]),
    }),
    [documentActions.restore.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.document.id]: action.payload.document,
      },
      scoped: restoringTrashedDocumentFromScopedCollections(state, [action.payload.document.id]),
    }),
    [documentActions.destroy.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: null,
      },
      scoped: removingDocumentsFromScopedCollections(state, [action.payload.id]),
    }),
    [documentActions.destroyList.success]: (state, action) => {
      const documentIds = action.payload.documents.map((d) => d.id);
      const byId = { ...state.byId };

      documentIds.forEach((documentId) => {
        byId[documentId] = null;
      });

      return {
        ...state,
        byId,
        scoped: removingDocumentsFromScopedCollections(state, documentIds),
      };
    },
    [documentActions.ocrAnalysis.begin.success]: (state, action) => {
      const { task } = action.payload.data;

      return {
        ...state,
        ocrAnalysisTasksByDocumentFileId: {
          ...state.ocrAnalysisTasksByDocumentFileId,
          [task.documentFileId]: task,
        },
      };
    },
    [documentActions.ocrAnalysis.status.success]: (state, action) => {
      const { task } = action.payload.data;

      return {
        ...state,
        ocrAnalysisTasksByDocumentFileId: {
          ...state.ocrAnalysisTasksByDocumentFileId,
          [task.documentFileId]: task,
        },
      };
    },
    [transactionLineCollectionActions.transactionLine.convert.success]: (state, action) => ({
      ...updatingDocument(state, action),
    }),
    [transactionLineCollectionActions.transactionLine.convertMultiple.success]: (
      state,
      action,
    ) => ({
      ...updatingDocuments(state, {
        payload: { documents: action.payload.map((res) => res.document) },
      }),
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [documentActions.list.request]: (state, action) => {
      let newState = {
        ...state,
        list: { ...state.list },
      };

      if (action.payload.filter) {
        const scope = composeDocumentFilterScope(action.payload.filter);

        newState = {
          ...newState,
          list: {
            ...newState.list,
            loadingScopes: [...newState.list.loadingScopes, scope],
            loadedScopes: newState.list.loadedScopes.filter((s) => s !== scope),
          },
        };
      }

      return newState;
    },
    [documentActions.list.success]: (state, action) => {
      let newState = {
        ...state,
        list: { ...state.list, loading: false, loaded: true },
      };

      if (action.payload.filter) {
        const scope = composeDocumentFilterScope(action.payload.filter);
        newState = {
          ...newState,
          list: {
            ...newState.list,
            loadingScopes: newState.list.loadingScopes.filter((s) => s !== scope),
            loadedScopes: [...newState.list.loadedScopes, scope],
          },
        };
      }

      return newState;
    },
    [documentActions.list.error]: (state) => ({
      ...state,
      list: { ...state.list, loading: false, loaded: false, error: true },
    }),
    [documentActions.listMeta.request]: (state) => ({
      ...state,
      meta: { loading: true, loaded: false, error: false },
    }),
    [documentActions.listMeta.success]: (state) => ({
      ...state,
      meta: { loading: false, loaded: true, error: false },
    }),
    [documentActions.listMeta.error]: (state) => ({
      ...state,
      meta: { loading: false, loaded: false, error: true },
    }),
    [documentActions.get.request]: (state, action) => ({
      ...state,
      get: {
        ...state.get,
        loadingIds: [...state.get.loadingIds, action.payload.documentId],
      },
    }),
    [documentActions.get.success]: (state, action) => ({
      ...state,
      get: {
        ...state.get,
        loadingIds: state.get.loadingIds.filter((id) => id !== action.payload.document.id),
        errorIds: state.get.errorIds.filter((id) => id !== action.payload.document.id),
        loadedIds: [...state.get.loadedIds, action.payload.document.id],
      },
    }),
    [documentActions.get.error]: (state, action) => ({
      ...state,
      get: {
        ...state.get,
        loadingIds: state.get.loadingIds.filter((id) => id !== action.payload.documentId),
        errorIds: [...state.get.errorIds, action.payload.documentId],
      },
    }),
    [documentActions.create.request]: (state) => ({
      ...state,
      create: { creating: true, created: false, error: false },
    }),
    [documentActions.create.success]: (state) => ({
      ...state,
      create: { creating: false, created: true, error: false },
    }),
    [documentActions.update.request]: (state, action) => ({
      ...state,
      processing: {
        ...state.processing,
        updatingDocumentIds: uniq([
          ...state.processing.updatingDocumentIds,
          action.payload.documentId,
        ]),
      },
    }),
    [documentActions.update.success]: (state, action) => ({
      ...state,
      processing: {
        ...state.processing,
        updatingDocumentIds: state.processing.updatingDocumentIds.filter(
          (id) => id !== action.payload.document.id,
        ),
      },
    }),
    [documentActions.update.error]: (state, action) => ({
      ...state,
      processing: {
        ...state.processing,
        updatingDocumentIds: state.processing.updatingDocumentIds.filter(
          (id) => id !== action.payload.documentId,
        ),
      },
    }),
    [documentActions.upload.request]: (state, action) => ({
      ...state,
      upload: {
        ...state.upload,
        uploadingFiles: [...state.upload.uploadingFiles, action.payload.file],
      },
    }),
    [documentActions.upload.success]: (state, action) => ({
      ...state,
      upload: {
        ...state.upload,
        uploadingFiles: state.upload.uploadingFiles.filter((f) => f !== action.payload.file),
        uploadedFiles: [...state.upload.uploadedFiles, action.payload.file],
      },
    }),
    [documentActions.upload.error]: (state, action) => ({
      ...state,
      upload: {
        ...state.upload,
        uploadingFiles: state.upload.uploadingFiles.filter((f) => f !== action.payload.file),
        errorFiles: [...state.upload.errorFiles, action.payload.file],
      },
    }),
    [documentActions.replaceFile.success]: (state, action) => ({
      ...state,
      upload: {
        ...state.upload,
        uploadingFiles: state.upload.uploadingFiles.filter((f) => f !== action.payload.file),
        uploadedFiles: [...state.upload.uploadedFiles, action.payload.file],
      },
    }),
    [documentActions.replaceFile.error]: (state, action) => ({
      ...state,
      upload: {
        ...state.upload,
        uploadingFiles: state.upload.uploadingFiles.filter((f) => f !== action.payload.file),
        errorFiles: [...state.upload.errorFiles, action.payload.file],
      },
    }),
    [documentActions.deleteFile.request]: (state, action) => ({
      ...state,
      deleteFile: {
        ...state.deleteFile,
        deletingDocumentFileIds: [
          ...state.deleteFile.deletingDocumentFileIds,
          action.payload.documentFileId,
        ],
      },
    }),
    [documentActions.deleteFile.success]: (state, action) => ({
      ...state,
      deleteFile: {
        ...state.deleteFile,
        deletingDocumentFileIds: state.deleteFile.deletingDocumentFileIds.filter(
          (id) => id !== action.payload.documentFileId,
        ),
      },
    }),
    [documentActions.deleteFile.error]: (state, action) => ({
      ...state,
      deleteFile: {
        ...state.deleteFile,
        deletingDocumentFileIds: state.deleteFile.deletingDocumentFileIds.filter(
          (id) => id !== action.payload.documentFileId,
        ),
        errorDeletingDocumentFileIds: [
          ...state.deleteFile.errorDeletingDocumentFileIds,
          action.payload.documentFileId,
        ],
      },
    }),
    [documentActions.processFile.processUpdate]: (state, action) => {
      const processingDocumentFiles = state.processing.processingDocumentFiles.filter(
        (df) => df.id !== action.payload.documentFileId,
      );

      return {
        ...state,
        processing: {
          ...state.processing,
          processingDocumentFiles: [
            ...processingDocumentFiles,
            {
              id: action.payload.documentFileId,
              status: action.payload.status,
            },
          ],
        },
      };
    },
    [documentActions.processFile.clear]: (state, action) => ({
      ...state,
      processing: {
        ...state.processing,
        processingDocumentFiles: state.processing.processingDocumentFiles.filter(
          (df) => df.id !== action.payload.documentFileId,
        ),
      },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
