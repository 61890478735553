import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { localesActions } from "./actions";

const initialStates = {
  collections: {
    all: [],
    timeZonesToRegion: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
    listTimeZoneToRegionMapping: {
      loading: false,
      loaded: false,
    },
  },
};

const collections = handleActions(
  {
    [localesActions.list.success]: (state, action) => ({
      ...state,
      all: action.payload.locales,
    }),
    [localesActions.timeZonesToRegion.list.success]: (state, action) => ({
      ...state,
      timeZonesToRegion: action.payload.timeZoneToRegionMap,
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [localesActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [localesActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [localesActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
    [localesActions.timeZonesToRegion.list.request]: (state) => ({
      ...state,
      listTimeZoneToRegionMapping: { loading: true, loaded: false },
    }),
    [localesActions.timeZonesToRegion.list.success]: (state) => ({
      ...state,
      listTimeZoneToRegionMapping: { loading: false, loaded: true },
    }),
    [localesActions.timeZonesToRegion.list.error]: (state) => ({
      ...state,
      listTimeZoneToRegionMapping: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
