import { createAction } from "redux-actions";

const namespaces = {
  bankStatements: "BANK-STATEMENTS",
};

export const bankStatementActions = {
  extract: {
    quota: {
      request: createAction(`${namespaces.bankStatements}/EXTRACT/QUOTA/REQUEST`),
      success: createAction(`${namespaces.bankStatements}/EXTRACT/QUOTA/SUCCESS`),
      error: createAction(`${namespaces.bankStatements}/EXTRACT/QUOTA/ERROR`),
    },
    getTask: {
      byDocumentId: {
        request: createAction(
          `${namespaces.bankStatements}/EXTRACT/GET-TASK/BY-DOCUMENT-ID/REQUEST`,
        ),
        success: createAction(
          `${namespaces.bankStatements}/EXTRACT/GET-TASK/BY-DOCUMENT-ID/SUCCESS`,
        ),
        error: createAction(`${namespaces.bankStatements}/EXTRACT/GET-TASK/BY-DOCUMENT-ID/ERROR`),
      },
    },
    list: {
      request: createAction(`${namespaces.bankStatements}/EXTRACT/LIST-TASKS/REQUEST`),
      success: createAction(`${namespaces.bankStatements}/EXTRACT/LIST-TASKS/SUCCESS`),
      error: createAction(`${namespaces.bankStatements}/EXTRACT/LIST-TASKS/ERROR`),
    },
    begin: {
      request: createAction(`${namespaces.bankStatements}/EXTRACT/BEGIN/REQUEST`),
      success: createAction(`${namespaces.bankStatements}/EXTRACT/BEGIN/SUCCESS`),
      error: createAction(`${namespaces.bankStatements}/EXTRACT/BEGIN/ERROR`),
    },
    status: {
      request: createAction(`${namespaces.bankStatements}/EXTRACT/STATUS/REQUEST`),
      success: createAction(`${namespaces.bankStatements}/EXTRACT/STATUS/SUCCESS`),
      error: createAction(`${namespaces.bankStatements}/EXTRACT/STATUS/ERROR`),

      poll: {
        request: createAction(`${namespaces.bankStatements}/EXTRACT/STATUS/POLL/REQUEST`),
        success: createAction(`${namespaces.bankStatements}/EXTRACT/STATUS/POLL/SUCCESS`),
        error: createAction(`${namespaces.bankStatements}/EXTRACT/STATUS/POLL/ERROR`),
      },
    },
  },
};
