import * as backendService from "../backendService";
import { fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const list = async ({
  organizationId,
}) => {
  const url = `/organizations/${organizationId}/document-categories`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};
