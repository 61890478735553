import { createAction } from "redux-actions";

const namespaces = {
  subscriptions: "SUBSCRIPTIONS",
};

export const subscriptionActions = {
  redeemProductCoupon: {
    request: createAction(`${namespaces.subscriptions}/CREATE/REQUEST`),
    success: createAction(`${namespaces.subscriptions}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.subscriptions}/CREATE/ERROR`),
  },
};
