import { put } from "redux-saga/effects";
import { logAnalyticsEvent } from "../analytics/analyticsService";
import { currencyActions } from "../currency/actions";
import { documentCategoryActions } from "../document-categories/actions";
import { documentActions } from "../documents/actions";
import { composeDocumentFilterScope } from "../documents/scopes/documentScopes";
import { organizationActions } from "../organization/actions";
import * as actions from "./actions";
import { itemCategoryActions } from "../item-categories/actions";
import { taxRateActions } from "../tax-rates/actions";
import { appConfigurationActions } from "../app-configurations/actions";
import { hashTagActions } from "../hash-tags/actions";
import { paymentMethodActions } from "../payment-methods/actions";
import { accountableActions } from "../accountables/actions";
import { composeOrganizationQuotaCountFilter } from "../../components/access-control/DocumentQuotaGuard";

export const dashboard = {
  *init(action) {
    try {
      const { organizationId } = action.payload;

      const freeQuotaUsedThisMonthFilter = composeOrganizationQuotaCountFilter();

      yield put(
        documentActions.count.request({
          organizationId,
          filter: freeQuotaUsedThisMonthFilter,
          scope: composeDocumentFilterScope(freeQuotaUsedThisMonthFilter),
        }),
      );

      yield put(appConfigurationActions.get.request());
      yield put(documentActions.listMeta.request({ organizationId }));
      yield put(hashTagActions.list.request({ organizationId }));
      yield put(paymentMethodActions.list.request({ organizationId }));
      yield put(documentCategoryActions.list.request({ organizationId }));
      yield put(itemCategoryActions.list.request({ organizationId }));
      yield put(taxRateActions.list.request({ organizationId }));
      yield put(currencyActions.list.request());
      yield put(organizationActions.initTimeZone.request({ organizationId }));
      yield put(organizationActions.getCurrentSubscription.request({ organizationId }));
      yield put(organizationActions.users.list.request({ organizationId }));
      yield put(accountableActions.list.request({ organizationId }));

      logAnalyticsEvent("dashboard_loaded");

      yield put(actions.dashboard.init.success());
    } catch (error) {
      yield put(actions.dashboard.init.error(error));
      throw error;
    }
  },
};
