import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const listDocumentExports = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/documents/document-exports`;

  const response = await backendService.get({
    url,
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const exportDocuments = async ({ organizationId, filter, config, format, name }) => {
  const url = `/organizations/${organizationId}/documents/export`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      filter,
      format,
      name,
      ...config,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const exportDocumentsStatus = async ({ organizationId, documentExportId }) => {
  const url = `/organizations/${organizationId}/documents/export-status`;
  const response = await backendService.get({ url, data: fromCamelToSnake({ documentExportId }) });
  return { data: fromSnakeToCamel(response.data) };
};

export const destroyDocumentExport = async ({ organizationId, documentExportId }) => {
  const url = `/organizations/${organizationId}/documents/export`;
  const response = await backendService.destroy({
    url,
    data: fromCamelToSnake({ documentExportId }),
  });
  return { data: fromSnakeToCamel(response.data) };
};
