import { call, put } from "redux-saga/effects";
import * as api from "./api";
import { currencyActions } from "./actions";
import { debugPrintAndLogError } from "../logging/logger";

export const currencySagas = {
  *list() {
    try {
      const response = yield call(api.list);
      yield put(currencyActions.list.success(response.data));
    } catch (error) {
      yield put(currencyActions.list.error(error));
      debugPrintAndLogError(error);
    }
  },
  *convert(action) {
    try {
      const { date, from, to, onCompletion = null } = action.payload;

      const response = yield call(api.convert, {
        date,
        from,
        to,
      });

      if (onCompletion) {
        onCompletion(response.data.conversion);
      }

      yield put(currencyActions.convert.success(response.data));
    } catch (error) {
      yield put(currencyActions.convert.error(error));

      if (action.payload.onError) {
        action.payload.onError(error);
      }

      debugPrintAndLogError(error);
    }
  },
};
