import React from "react";
import PropTypes from "prop-types";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import ApplicationRoutes from "./routes/index.jsx";
import "moment-timezone";

import "./App.scss";
import { initializeUetTags } from "./services/tracking/microsoftUet.js";
import { i18n } from "./i18n";
import { initializeFirebase } from "./services/tracking/firebase.js";
import { TrackAffiliateProgram } from "./services/tracking/affiliateProgram.js";
import { initializeGA4 } from "./services/tracking/ga4.js";

const googleOAuthClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

initializeUetTags();
initializeFirebase();
initializeGA4();

function App({ store, history }) {
  return (
    <Provider store={store}>
      <TrackAffiliateProgram />
      <I18nextProvider i18n={i18n}>
        <Router history={history}>
          <GoogleOAuthProvider clientId={googleOAuthClientId}>
            <ApplicationRoutes />
          </GoogleOAuthProvider>
        </Router>
        <div id="overlay-container"></div>
      </I18nextProvider>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default App;
