import { createAction } from "redux-actions";

const namespaces = {
  documentExports: "DOCUMENT-EXPORTS",
};

export const documentExportActions = {
  list: {
    request: createAction(`${namespaces.documentExports}/LIST/REQUEST`),
    success: createAction(`${namespaces.documentExports}/LIST/SUCCESS`),
    error: createAction(`${namespaces.documentExports}/LIST/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.documentExports}/CREATE/REQUEST`),
    success: createAction(`${namespaces.documentExports}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.documentExports}/CREATE/ERROR`),
  },
  status: {
    request: createAction(`${namespaces.documentExports}/STATUS/REQUEST`),
    success: createAction(`${namespaces.documentExports}/STATUS/SUCCESS`),
    error: createAction(`${namespaces.documentExports}/STATUS/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.documentExports}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.documentExports}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.documentExports}/DESTROY/ERROR`),
  },
};
