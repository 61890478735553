import React from "react";
import { clamp, isEqual, sum } from "lodash";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { debugPrintAndLogError } from "../logging/logger";

export function getDocumentTotalValue(document, { addSign = false, includeTip = true } = {}) {
  const { documentValueLines } = document;

  if (documentValueLines.length === 0) {
    return 0;
  }

  let result = sum(documentValueLines.map((l) => Number.parseFloat(l.grossValue)));

  if (includeTip) {
    result += Number.parseFloat(document.tipAmount);
  }

  if (addSign && document.transactionType === "expense") {
    result *= -1;
  }

  return result;
}

export function getDocumentTotalTax(document) {
  const { documentValueLines } = document;

  if (documentValueLines.length === 0) {
    return 0;
  }

  return sum(documentValueLines.map((l) => Number.parseFloat(l.taxAmount)));
}

export function shouldShowValue(document) {
  return ["expense", "income"].includes(document.transactionType);
}

export function isIncomeDocument(document) {
  return document.transactionType === "income";
}

export function isExpenseDocument(document) {
  return document.transactionType === "expense";
}

export function getEffectiveTaxRate(documentValueLines, taxMode) {
  let totalGrossValue = 0.0;
  let totalTaxAmount = 0.0;

  documentValueLines.forEach((line) => {
    totalGrossValue += Number.parseFloat(line.grossValue);
    totalTaxAmount += Number.parseFloat(line.taxAmount);
  });

  if (totalGrossValue === 0) {
    return 0.0;
  }
  if (totalGrossValue - totalTaxAmount <= 0) {
    return 100.0;
  }

  if (taxMode === "exclusive") {
    return (totalTaxAmount / totalGrossValue) * 100;
  }

  let taxRate = (totalTaxAmount / (totalGrossValue - totalTaxAmount)) * 100;
  taxRate = clamp(taxRate, -100, 100);

  return taxRate;
}

export function equalDocumentLists(docListA, docListB) {
  // Check if lengths are different
  if (docListA.length !== docListB.length) {
    return false;
  }

  // Check if documents at the same position in both lists are different
  for (let i = 0; i < docListA.length; i += 1) {
    if (!equalDocuments(docListA[i], docListB[i])) {
      return false;
    }
  }

  // If all documents matched, the lists are equal
  return true;
}

export function equalDocuments(documentA, documentB) {
  const equalFields =
    documentA.id === documentB.id &&
    documentA.name === documentB.name &&
    documentA.description === documentB.description &&
    documentA.documentTime === documentB.documentTime &&
    documentA.transactionType === documentB.transactionType &&
    documentA.documentCategoryId === documentB.documentCategoryId &&
    documentA.itemCategoryId === documentB.itemCategoryId &&
    documentA.trashed === documentB.trashed &&
    documentA.trashedAt === documentB.trashedAt &&
    documentA.organizationUserId === documentB.organizationUserId &&
    isEqual(documentA.ocrRecognizedFields, documentB.ocrRecognizedFields) &&
    isEqual(
      documentA.hashTags.map((h) => h.name),
      documentB.hashTag.map((h) => h.name),
    ) &&
    documentA.currencyCode === documentB.currencyCode;

  const equalValueLines = equalDocumentValueLines(
    documentA.documentValueLines,
    documentB.documentValueLines,
  );

  return equalFields && equalValueLines;
}

export function equalDocumentValueLines(documentValueLinesA, documentValueLinesB) {
  if (
    (documentValueLinesA && !documentValueLinesB) ||
    (!documentValueLinesA && documentValueLinesB)
  ) {
    return false;
  }
  if (documentValueLinesA.length !== documentValueLinesB.length) {
    return false;
  }

  let equal = true;

  documentValueLinesA.forEach((a, i) => {
    const b = documentValueLinesB[i];

    equal = equal && Number.parseFloat(a.grossValue) === Number.parseFloat(b.grossValue);
    equal =
      equal && equalDocumentValueLineTaxes(a.documentValueLineTaxes, b.documentValueLineTaxes);
  });

  return equal;
}

export function equalDocumentValueLineTaxes(documentValueLineTaxesA, documentValueLineTaxesB) {
  if (
    (documentValueLineTaxesA && !documentValueLineTaxesB) ||
    (!documentValueLineTaxesA && documentValueLineTaxesB)
  ) {
    return false;
  }
  if (documentValueLineTaxesA.length !== documentValueLineTaxesB.length) {
    return false;
  }

  let equal = true;

  documentValueLineTaxesA.forEach((a, i) => {
    const b = documentValueLineTaxesB[i];

    const customTaxA = Number.parseFloat(a.customTaxRate) || 0;
    const customTaxB = Number.parseFloat(b.customTaxRate) || 0;

    const taxAmountA = Number.parseFloat(a.taxAmount) || 0;
    const taxAmountB = Number.parseFloat(b.taxAmount) || 0;

    equal = equal && customTaxA === customTaxB;
    equal = equal && taxAmountA === taxAmountB;
    equal = equal && a.taxKind === b.taxKind;
  });

  return equal;
}

export function sortDocumentMonths(documentMonths) {
  // Create a new array from the input to avoid mutating it
  return [...documentMonths].sort((a, b) => {
    // Compare the year first
    if (a.year !== b.year) {
      return a.year - b.year;
    }
    // If the year is the same, then compare the month
    return a.month - b.month;
  });
}

export function fillDocumentMonthBlanks(documentMonths) {
  const sortedMonths = sortDocumentMonths(documentMonths);

  const firstMonth = sortedMonths[0];
  const lastMonth = sortedMonths[sortedMonths.length - 1];

  const filledMonths = [];

  let currentMonth = { year: firstMonth.year, month: firstMonth.month };
  let maxIterations = 1000;

  while (
    (currentMonth.year < lastMonth.year || currentMonth.month <= lastMonth.month) &&
    maxIterations > 0
  ) {
    filledMonths.push({ ...currentMonth });

    if (currentMonth.month === 12) {
      currentMonth = { year: currentMonth.year + 1, month: 1 };
    } else {
      currentMonth = { year: currentMonth.year, month: currentMonth.month + 1 };
    }

    maxIterations -= 1;
  }

  return filledMonths;
}

export function isBlankDocument(document) {
  try {
    const {
      name = "",
      description = "",
      documentValueLines = [{ documentValueLineTaxes: [] }],
      documentFiles = [],
      hashTags = [],
      trashed = false,
    } = document;

    const basicInfoBlank = name === "" && description === "" && documentValueLines.length < 2;

    const totalValue = getDocumentTotalValue(document);
    const tax = getEffectiveTaxRate(documentValueLines, document.taxMode);
    const valueBlank = totalValue === 0 && tax === 0;

    const itemCategoryBlank = document.itemCategoryId === null;

    return (
      basicInfoBlank &&
      valueBlank &&
      itemCategoryBlank &&
      hashTags.length === 0 &&
      documentFiles.length === 0 &&
      !trashed
    );
  } catch (error) {
    debugPrintAndLogError(error);
    return false;
  }
}

export function getIconForDocumentReviewStatus(reviewStatus, { fontSize = "medium" } = {}) {
  switch (reviewStatus) {
    case "reviewed":
      return <DoneAllIcon htmlColor="green" fontSize={fontSize} />;
    case "rejected":
      return <ErrorOutlineIcon htmlColor="orange" fontSize={fontSize} />;
    default:
      return <HelpOutlineIcon fontSize={fontSize} />;
  }
}
