import { createAction } from "redux-actions";

const namespaces = {
  taxRates: "TAX-RATES",
};

export const taxRateActions = {
  list: {
    clear: createAction(`${namespaces.taxRates}/LIST/CLEAR`),
    request: createAction(`${namespaces.taxRates}/LIST/REQUEST`),
    success: createAction(`${namespaces.taxRates}/LIST/SUCCESS`),
    error: createAction(`${namespaces.taxRates}/LIST/ERROR`),
  },
  listAccountingTaxCodes: {
    request: createAction(`${namespaces.taxRates}/LIST-ACCOUNTING-TAX-CODES/REQUEST`),
    success: createAction(`${namespaces.taxRates}/LIST-ACCOUNTING-TAX-CODES/SUCCESS`),
    error: createAction(`${namespaces.taxRates}/LIST-ACCOUNTING-TAX-CODES/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.taxRates}/CREATE/REQUEST`),
    success: createAction(`${namespaces.taxRates}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.taxRates}/CREATE/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.taxRates}/UPDATE/REQUEST`),
    success: createAction(`${namespaces.taxRates}/UPDATE/SUCCESS`),
    error: createAction(`${namespaces.taxRates}/UPDATE/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.taxRates}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.taxRates}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.taxRates}/DESTROY/ERROR`),
  },
};
