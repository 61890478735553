import { call, put } from "redux-saga/effects";
import * as api from "./api";
import { taxRateActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const taxRateSagas = {
  *list(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload || {};
      const response = yield call(api.list, { organizationId });
      yield put(taxRateActions.list.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(taxRateActions.list.error(error));
    }
  },
  *listAccountingTaxCodes(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload || {};
      const response = yield call(api.listAccountingTaxCodes, { organizationId });
      yield put(taxRateActions.listAccountingTaxCodes.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(taxRateActions.listAccountingTaxCodes.error(error));
    }
  },
  *create(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        taxRate,
        secondaryTaxRate = null,
        taxKind,
        accountingTaxCodeId,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.create, {
        organizationId,
        taxRate,
        secondaryTaxRate,
        taxKind,
        accountingTaxCodeId,
      });
      yield put(taxRateActions.create.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(taxRateActions.create.error(error));
    }
  },
  *update(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        taxRate,
        secondaryTaxRate = null,
        taxKind,
        accountingTaxCodeId,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.update, {
        organizationId,
        id,
        taxRate,
        secondaryTaxRate,
        taxKind,
        accountingTaxCodeId,
      });

      yield put(taxRateActions.update.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(taxRateActions.update.error(error));
    }
  },
  *destroy(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.destroy, { organizationId, id });
      yield put(taxRateActions.destroy.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(taxRateActions.destroy.error(error));
    }
  },
};
