import { call, put } from "redux-saga/effects";
import * as api from "./api";
import { itemCategoryActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const itemCategorySagas = {
  *list(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), onCompletion = () => {} } =
        action.payload;
      const response = yield call(api.list, { organizationId });
      yield put(itemCategoryActions.list.success(response.data));

      onCompletion(response.data.itemCategories);
    } catch (error) {
      yield put(itemCategoryActions.list.error(error));
      debugPrintAndLogError(error);

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },
  *create(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        name,
        categoryCode,
        transactionType,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.create, {
        organizationId,
        name,
        transactionType,
        categoryCode,
      });
      yield put(itemCategoryActions.create.success(response.data));
      onCompletion(response.data.itemCategory);
    } catch (error) {
      yield put(itemCategoryActions.create.error(error));
      debugPrintAndLogError(error);
    }
  },
  *update(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        name,
        categoryCode,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.update, { organizationId, id, name, categoryCode });
      yield put(itemCategoryActions.update.success(response.data));
      onCompletion(response.data.itemCategory);
    } catch (error) {
      yield put(itemCategoryActions.update.error(error));
      debugPrintAndLogError(error);
    }
  },
  *destroy(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.destroy, { organizationId, id });
      yield put(itemCategoryActions.destroy.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      yield put(itemCategoryActions.destroy.error(error));
      debugPrintAndLogError(error);
    }
  },
};
