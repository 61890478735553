import { call, put } from "redux-saga/effects";
import { hashTagActions } from "./actions";
import { hashTagsApi } from "./api";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const hashTagsSagas = {
  *list({ payload = {} }) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), onCompletion = () => {} } =
        payload;
      const response = yield call(hashTagsApi.list, { organizationId });
      yield put(hashTagActions.list.success(response.data));

      onCompletion(response.data);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(hashTagActions.list.error(e));
    }
  },
  *update({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        name,
        onCompletion = () => {},
      } = payload;

      const response = yield call(hashTagsApi.update, { organizationId, id, name });
      yield put(hashTagActions.update.success(response.data));
      onCompletion(response.data.hashTag);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(hashTagActions.update.error(e));
    }
  },
  *destroy({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
      } = payload;
      const response = yield call(hashTagsApi.destroy, { organizationId, id });
      yield put(hashTagActions.destroy.success(response.data));
      onCompletion(response.data);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(hashTagActions.destroy.error(e));
    }
  },
};
