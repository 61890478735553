import React, { useState, useEffect } from "react";

const DelayedFallback = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 0);

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      {show && "..."}
    </>
  )
};

export default DelayedFallback;
