import { createAction } from "redux-actions";

const namespaces = {
  accountables: "ACCOUNTABLES",
};

export const accountableActions = {
  list: {
    request: createAction(`${namespaces.accountables}/LIST/REQUEST`),
    success: createAction(`${namespaces.accountables}/LIST/SUCCESS`),
    error: createAction(`${namespaces.accountables}/LIST/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.accountables}/CREATE/REQUEST`),
    success: createAction(`${namespaces.accountables}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.accountables}/CREATE/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.accountables}/UPDATE/REQUEST`),
    success: createAction(`${namespaces.accountables}/UPDATE/SUCCESS`),
    error: createAction(`${namespaces.accountables}/UPDATE/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.accountables}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.accountables}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.accountables}/DESTROY/ERROR`),
  },
};
