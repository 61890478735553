import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { itemCategoryActions } from "./actions";

const initialStates = {
  collections: {
    all: [],
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
    create: {
      loading: false,
      loaded: false,
    },
    update: {
      loading: false,
      loaded: false,
    },
    destroy: {
      loading: false,
      loaded: false,
    },
  },
};

const addingOrReplacingItemCategory = (itemCategories, newItemCategory) => {
  const itemIndex = itemCategories.findIndex((c) => c.id === newItemCategory.id);

  if (itemIndex === -1) {
    // If the item does not exist, append it to the end
    return [...itemCategories, newItemCategory];
  }

  // Otherwise, replace the existing item while preserving order
  return [
    ...itemCategories.slice(0, itemIndex),
    newItemCategory,
    ...itemCategories.slice(itemIndex + 1),
  ];
};

const collections = handleActions(
  {
    [itemCategoryActions.list.success]: (state, action) => ({
      ...state,
      all: action.payload.itemCategories,
    }),
    [itemCategoryActions.create.success]: (state, action) => ({
      ...state,
      all: [...state.all, action.payload.itemCategory],
    }),
    [itemCategoryActions.update.success]: (state, action) => ({
      ...state,
      all: addingOrReplacingItemCategory(state.all, action.payload.itemCategory),
    }),
    [itemCategoryActions.destroy.success]: (state, action) => ({
      ...state,
      all: state.all.filter((itemCategory) => itemCategory.id !== action.payload.id),
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [itemCategoryActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: state.list.loaded || false },
    }),
    [itemCategoryActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [itemCategoryActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
    [itemCategoryActions.create.request]: (state) => ({
      ...state,
      create: { loading: true, loaded: false },
    }),
    [itemCategoryActions.create.success]: (state) => ({
      ...state,
      create: { loading: false, loaded: true },
    }),
    [itemCategoryActions.create.error]: (state) => ({
      ...state,
      create: { loading: false, loaded: false, error: true },
    }),
    [itemCategoryActions.update.request]: (state) => ({
      ...state,
      update: { loading: true, loaded: false },
    }),
    [itemCategoryActions.update.success]: (state) => ({
      ...state,
      update: { loading: false, loaded: true },
    }),
    [itemCategoryActions.update.error]: (state) => ({
      ...state,
      update: { loading: false, loaded: false, error: true },
    }),
    [itemCategoryActions.destroy.request]: (state) => ({
      ...state,
      destroy: { loading: true, loaded: false },
    }),
    [itemCategoryActions.destroy.success]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: true },
    }),
    [itemCategoryActions.destroy.error]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
