import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const list = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-line-collections`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const find = async ({ organizationId, id }) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-line-collections/${id}`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const findByDocumentId = async ({ organizationId, documentId }) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-line-collections/by-document/${documentId}`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroy = async ({ organizationId, id }) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-line-collections/${id}`;
  const response = await backendService.destroy({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const updateTransactionLine = async ({
  organizationId,
  id,
  name,
  date,
  description,
  reference,
  amount,
  currencyCode,
  documentId,
  markUnmatched,
}) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-lines/${id}`;
  const response = await backendService.put({
    url,
    data: fromCamelToSnake({
      name,
      date,
      description,
      reference,
      amount,
      currencyCode,
      documentId,
      markUnmatched,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const updateMultipleTransactionLines = async ({
  organizationId,
  ids,
  name,
  description,
  reference,
  amount,
  currencyCode,
  markUnmatched,
}) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-lines/update-multiple`;

  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({
      ids,
      name,
      description,
      reference,
      amount,
      currencyCode,
      markUnmatched,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroyTransactionLine = async ({ organizationId, id }) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-lines/${id}`;
  const response = await backendService.destroy({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const convertTransactionLine = async ({
  organizationId,
  id,
  mergeTactic = "merge_combine",
  skipConverted = true,
}) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-lines/${id}/convert`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ mergeTactic, skipConverted }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const convertTransactionLines = async ({
  organizationId,
  ids,
  mergeTactic = "merge_combine",
  skipConverted = true,
}) => {
  const url = `/organizations/${organizationId}/bank-statements/transaction-lines/convert-multiple`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ ids, mergeTactic, skipConverted }),
  });

  return { data: fromSnakeToCamel(response.data) };
};
