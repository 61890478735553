import i18next from "i18next";
import { get } from "lodash";

import { configuration } from "../config";

let momentLocale = null;

export const getCurrentLanguage = () => {
  const supportedLanguages = Object.keys(configuration.languages.ui.resources);
  const fallbackLanguage = configuration.languages.ui.fallback;
  const language = get(i18next, "languages[0]", null) || fallbackLanguage;

  if (supportedLanguages.includes(language)) {
    return language;
  }

  i18next.changeLanguage(fallbackLanguage);
  return fallbackLanguage;
};

export const rememberMomentLocale = (locale) => {
  momentLocale = locale;
};

export const getStoredMomentLocale = () => `${momentLocale}`;
