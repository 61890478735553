import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { appConfigurationActions } from "./actions";

const initialStates = {
  collections: {
    all: {},
  },
  status: {
    get: {
      loading: false,
      loaded: false,
    },
  },
};

const collections = handleActions(
  {
    [appConfigurationActions.get.success]: (state, action) => ({
      ...state,
      all: action.payload,
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [appConfigurationActions.get.request]: (state) => ({
      ...state,
      get: { loading: true, loaded: false },
    }),
    [appConfigurationActions.get.success]: (state) => ({
      ...state,
      get: { loading: false, loaded: true },
    }),
    [appConfigurationActions.get.error]: (state) => ({
      ...state,
      get: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
