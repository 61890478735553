import { createAction } from "redux-actions";

const namespaces = {
  paymentMethodAccounts: "PAYMENT-METHOD-ACCOUNTS",
};

export const paymentMethodAccountActions = {
  list: {
    request: createAction(`${namespaces.paymentMethodAccounts}/LIST/REQUEST`),
    success: createAction(`${namespaces.paymentMethodAccounts}/LIST/SUCCESS`),
    error: createAction(`${namespaces.paymentMethodAccounts}/LIST/ERROR`),
  },
};
