export function setSingleSharePassword(shareCode, password) {
  localStorage.setItem(`documentShareCodePassword-${shareCode}`, password);
}

export function getSingleSharePassword(shareCode) {
  return localStorage.getItem(`documentShareCodePassword-${shareCode}`);
}

export function removeSingleSharePassword(shareCode) {
  localStorage.removeItem(`documentShareCodePassword-${shareCode}`);
}
