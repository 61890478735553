import { createAction } from "redux-actions";

const namespaces = {
  hashTags: "HASH-TAGS",
};

export const hashTagActions = {
  list: {
    request: createAction(`${namespaces.hashTags}/LIST/REQUEST`),
    success: createAction(`${namespaces.hashTags}/LIST/SUCCESS`),
    error: createAction(`${namespaces.hashTags}/LIST/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.hashTags}/PATCH/REQUEST`),
    success: createAction(`${namespaces.hashTags}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.hashTags}/PATCH/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.hashTags}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.hashTags}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.hashTags}/DESTROY/ERROR`),
  },
};
