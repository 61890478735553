import { getAnalytics, logEvent } from "firebase/analytics";
import ReactGA from "react-ga4";

export function logAnalyticsEvent(name, params = {}) {
  _logFirebaseEvent(name, params);
  _logGA4Event(name, params);
}

function _logFirebaseEvent(name, params) {
  logEvent(getAnalytics(), name, params);
}

function _logGA4Event(name, params) {
  ReactGA.event({ category: "Event", action: name, ...params });
}
