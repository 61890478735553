import { call, put } from "redux-saga/effects";
import * as api from "./api";
import { documentCategoryActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const documentCategorySagas = {
  *list(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload;
      const response = yield call(api.list, { organizationId });
      yield put(documentCategoryActions.list.success(response.data));
    } catch (error) {
      yield put(documentCategoryActions.list.error(error));
      debugPrintAndLogError(error);
    }
  },
};
