import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { organizationActions } from "./actions";

const initialStates = {
  collections: {
    currentSubscription: null,
    organizationUsers: {},
  },
  status: {
    currentSubscription: {
      loading: false,
      loaded: false,
      provisioning: false,
      provisioned: false,
    },
    organizationUsers: {
      loading: false,
      loaded: false,
    },
  },
};

const collections = handleActions(
  {
    [organizationActions.getCurrentSubscription.request]: (state) => ({
      ...state,
      currentSubscription: null,
    }),
    [organizationActions.getCurrentSubscription.success]: (state, action) => ({
      ...state,
      currentSubscription: {
        ...action.payload,
      },
    }),
    [organizationActions.users.list.success]: (state, action) => ({
      ...state,
      organizationUsers: {
        ...state.organizationUsers,
        ...action.payload.users.reduce((acc, organizationUser) => {
          acc[organizationUser.id] = organizationUser;
          return acc;
        }, {}),
      },
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [organizationActions.getCurrentSubscription.request]: (state) => ({
      ...state,
      currentSubscription: { loading: true, loaded: false },
    }),
    [organizationActions.getCurrentSubscription.success]: (state) => ({
      ...state,
      currentSubscription: { loading: false, loaded: true },
    }),
    [organizationActions.getCurrentSubscription.error]: (state) => ({
      ...state,
      currentSubscription: { loading: false, loaded: false, error: true },
    }),
    [organizationActions.users.list.request]: (state) => ({
      ...state,
      organizationUsers: { loading: true, loaded: false, error: false },
    }),
    [organizationActions.users.list.success]: (state) => ({
      ...state,
      organizationUsers: { loading: false, loaded: true, error: false },
    }),
    [organizationActions.users.list.error]: (state) => ({
      ...state,
      organizationUsers: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
