import { createAction } from "redux-actions";

const namespaces = {
  documents: "DOCUMENTS",
  documentFiles: "DOCUMENT-FILES",
};

export const documentActions = {
  list: {
    clear: createAction(`${namespaces.documents}/LIST/CLEAR`),
    request: createAction(`${namespaces.documents}/LIST/REQUEST`),
    success: createAction(`${namespaces.documents}/LIST/SUCCESS`),
    error: createAction(`${namespaces.documents}/LIST/ERROR`),
  },
  search: {
    request: createAction(`${namespaces.documents}/SEARCH/REQUEST`),
    success: createAction(`${namespaces.documents}/SEARCH/SUCCESS`),
    error: createAction(`${namespaces.documents}/SEARCH/ERROR`),
  },
  count: {
    request: createAction(`${namespaces.documents}/COUNT/REQUEST`),
    success: createAction(`${namespaces.documents}/COUNT/SUCCESS`),
    error: createAction(`${namespaces.documents}/COUNT/ERROR`),
  },
  totalValue: {
    request: createAction(`${namespaces.documents}/TOTAL-VALUE/REQUEST`),
    success: createAction(`${namespaces.documents}/TOTAL-VALUE/SUCCESS`),
    error: createAction(`${namespaces.documents}/TOTAL-VALUE/ERROR`),
  },
  listMeta: {
    request: createAction(`${namespaces.documents}/LIST-META/REQUEST`),
    success: createAction(`${namespaces.documents}/LIST-META/SUCCESS`),
    error: createAction(`${namespaces.documents}/LIST-META/ERROR`),
  },
  get: {
    request: createAction(`${namespaces.documents}/GET/REQUEST`),
    success: createAction(`${namespaces.documents}/GET/SUCCESS`),
    error: createAction(`${namespaces.documents}/GET/ERROR`),
  },
  getList: {
    request: createAction(`${namespaces.documents}/GET-LIST/REQUEST`),
    success: createAction(`${namespaces.documents}/GET-LIST/SUCCESS`),
    error: createAction(`${namespaces.documents}/GET-LIST/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.documents}/CREATE/REQUEST`),
    success: createAction(`${namespaces.documents}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.documents}/CREATE/ERROR`),
  },
  upload: {
    request: createAction(`${namespaces.documents}/UPLOAD/REQUEST`),
    success: createAction(`${namespaces.documents}/UPLOAD/SUCCESS`),
    error: createAction(`${namespaces.documents}/UPLOAD/ERROR`),
  },
  deleteFile: {
    request: createAction(`${namespaces.documents}/DELETE-FILE/REQUEST`),
    success: createAction(`${namespaces.documents}/DELETE-FILE/SUCCESS`),
    error: createAction(`${namespaces.documents}/DELETE-FILE/ERROR`),
  },
  replaceFile: {
    request: createAction(`${namespaces.documents}/REPLACE-FILE/REQUEST`),
    success: createAction(`${namespaces.documents}/REPLACE-FILE/SUCCESS`),
    error: createAction(`${namespaces.documents}/REPLACE-FILE/ERROR`),
  },
  uploadProcessedFile: {
    request: createAction(`${namespaces.documents}/UPLOAD-PROCESSED-FILE/REQUEST`),
    success: createAction(`${namespaces.documents}/UPLOAD-PROCESSED-FILE/SUCCESS`),
    error: createAction(`${namespaces.documents}/UPLOAD-PROCESSED-FILE/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.documents}/UPDATE/REQUEST`),
    success: createAction(`${namespaces.documents}/UPDATE/SUCCESS`),
    error: createAction(`${namespaces.documents}/UPDATE/ERROR`),
  },
  updateMultiple: {
    request: createAction(`${namespaces.documents}/UPDATE-MULTIPLE/REQUEST`),
    success: createAction(`${namespaces.documents}/UPDATE-MULTIPLE/SUCCESS`),
    error: createAction(`${namespaces.documents}/UPDATE-MULTIPLE/ERROR`),
  },
  moveToOrganization: {
    request: createAction(`${namespaces.documents}/MOVE-TO-ORGANIZATION/REQUEST`),
    success: createAction(`${namespaces.documents}/MOVE-TO-ORGANIZATION/SUCCESS`),
    error: createAction(`${namespaces.documents}/MOVE-TO-ORGANIZATION/ERROR`),
  },
  trash: {
    request: createAction(`${namespaces.documents}/TRASH/REQUEST`),
    success: createAction(`${namespaces.documents}/TRASH/SUCCESS`),
    error: createAction(`${namespaces.documents}/TRASH/ERROR`),
  },
  restore: {
    request: createAction(`${namespaces.documents}/RESTORE/REQUEST`),
    success: createAction(`${namespaces.documents}/RESTORE/SUCCESS`),
    error: createAction(`${namespaces.documents}/RESTORE/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.documents}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.documents}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.documents}/DESTROY/ERROR`),
  },
  destroyList: {
    request: createAction(`${namespaces.documents}/DESTROY-LIST/REQUEST`),
    success: createAction(`${namespaces.documents}/DESTROY-LIST/SUCCESS`),
    error: createAction(`${namespaces.documents}/DESTROY-LIST/ERROR`),
  },
  processFile: {
    processUpdate: createAction(`${namespaces.documents}/PROCESS-FILE/PROCESS-UPDATE`),
    clear: createAction(`${namespaces.documents}/PROCESS-FILE/CLEAR`),
  },
  ocrAnalysis: {
    begin: {
      request: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/BEGIN/REQUEST`),
      success: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/BEGIN/SUCCESS`),
      error: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/BEGIN/ERROR`),
    },
    status: {
      request: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/STATUS/REQUEST`),
      success: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/STATUS/SUCCESS`),
      error: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/STATUS/ERROR`),
    },
    completed: {
      success: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/COMPLETED/SUCCESS`),
      error: createAction(`${namespaces.documentFiles}/OCR-ANALYSIS/COMPLETED/ERROR`),
    },
  },
};
