/* eslint-disable arrow-body-style */
import pica from "pica";
import heic2any from "heic2any";

export async function convertHEICIfNeeded(file) {
  const isHeicFile = file.type.includes("heic") || file.type.includes("heif");
  if (!isHeicFile) {
    return file;
  }

  const blobURL = URL.createObjectURL(file);
  const blobRes = await fetch(blobURL);
  const blob = await blobRes.blob();

  // convert to JPG - response is blob
  const convertedBlob = await heic2any({ blob, toType: "image/jpeg", quality: 1 });

  return convertedBlob;
}

export async function prepareScaleImageWithCanvas(file, dimensions) {
  // ensure the file is an image
  if (!file.type.match(/image.*/)) return null;

  const image = new Image();
  image.src = URL.createObjectURL(file);

  await new Promise((res) => {
    image.onload = res;
  });

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d", { alpha: true });

  const requiredScaleW = dimensions.width / image.width;
  const requiredScaleH = dimensions.height / image.height;

  const requiredScale = Math.min(requiredScaleW, requiredScaleH);
  const destWidth = Math.min(image.width * requiredScale, image.width);
  const destHeight = Math.min(image.height * requiredScale, image.height);

  canvas.width = image.width;
  canvas.height = image.height;

  context.drawImage(image, 0, 0, image.width, image.height);

  return { canvas, destWidth, destHeight };
}

export function resizeImageBeforeUpload(file, maxDimensions) {
  const { width, height } = maxDimensions;

  return new Promise((resolve) => {
    convertHEICIfNeeded(file)
      .then((convertedFile) => {
        return prepareScaleImageWithCanvas(convertedFile, { width, height });
      })
      .then((prepareData) => {
        const sourceCanvas = prepareData.canvas;
        const destCanvas = document.createElement("canvas");

        destCanvas.width = prepareData.destWidth;
        destCanvas.height = prepareData.destHeight;

        return pica().resize(sourceCanvas, destCanvas);
      })
      .then((resizeResult) => {
        const quality = 0.85;
        return pica().toBlob(resizeResult, "image/jpeg", quality);
      })
      .then((blob) => {
        resolve(blob);
      })
      .catch((error) => {
        throw error;
      });
  });
}
