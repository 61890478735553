import { createAction } from "redux-actions";

const namespaces = {
  documentCategories: "DOCUMENT_CATEGORIES",
};

export const documentCategoryActions = {
  list: {
    clear: createAction(`${namespaces.documentCategories}/LIST/CLEAR`),
    request: createAction(`${namespaces.documentCategories}/LIST/REQUEST`),
    success: createAction(`${namespaces.documentCategories}/LIST/SUCCESS`),
    error: createAction(`${namespaces.documentCategories}/LIST/ERROR`),
  },
};
