import moment from "moment";
import { call, put, select } from "redux-saga/effects";
import * as api from "./api";
import { organizationActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { logAnalyticsEvent } from "../analytics/analyticsService";
import { debugPrintAndLogError } from "../logging/logger";
import { session } from "../login/actions";

export const organizationSagas = {
  *initTimeZone(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload;

      const organization = yield select((state) =>
        state.login.collections.loginInfo.organizations.find((o) => o.id === organizationId),
      );

      const shouldUpdate = !organization.timeZone || !organization.timeZoneGuessed;

      if (shouldUpdate) {
        yield put(organizationActions.update.request({ timeZone: moment.tz.guess() }));
      }

      yield put(
        organizationActions.initTimeZone.success({
          updated: shouldUpdate,
        }),
      );
    } catch (error) {
      yield put(organizationActions.initTimeZone.error(error));
      debugPrintAndLogError(error);
    }
  },
  *getCurrentSubscription(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload;
      const response = yield call(api.getCurrentSubscription, {
        organizationId,
      });

      yield put(organizationActions.getCurrentSubscription.success(response.data));
    } catch (error) {
      yield put(organizationActions.getCurrentSubscription.error(error));
      debugPrintAndLogError(error);
    }
  },
  *create(action) {
    try {
      const { name, copyCategoriesFromOrganizationId, onCompletion = () => {} } = action.payload;

      const response = yield call(api.createOrganization, {
        name,
        copyCategoriesFromOrganizationId,
      });

      logAnalyticsEvent("organization_create");

      yield put(organizationActions.create.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      yield put(organizationActions.create.error(error));
      debugPrintAndLogError(error);

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },
  *update(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        name = null,
        timeZone = null,
      } = action.payload;

      const response = yield call(api.editOrganization, {
        organizationId,
        name,
        timeZone,
      });

      logAnalyticsEvent("organization_edit");

      yield put(session.loginInfo.refresh());

      yield put(organizationActions.update.success(response.data));
    } catch (error) {
      yield put(
        organizationActions.update.error({
          error,
        }),
      );
      debugPrintAndLogError(error);
    }
  },
  users: {
    *list(action) {
      try {
        const { payload = {} } = action;
        const { organizationId = yield useCurrentOrganizationId() } = payload;
        const response = yield call(api.getOrganizationUsers, { organizationId });
        yield put(organizationActions.users.list.success(response.data));
      } catch (error) {
        yield put(organizationActions.users.list.error({ error }));
        debugPrintAndLogError(error);
      }
    },
  },
};
