/* eslint-disable no-console */
import * as Sentry from "@sentry/react";

export function debugPrint(message, logFunction = null) {
  if (process.env.NODE_ENV === "development") {
    if (typeof logFunction === "function") {
      logFunction(message);
    } else {
      console.log(message);
    }
  }
}

export function debugPrintAndLogError(error) {
  if (process.env.NODE_ENV === "development") {
    console.log(error);
  }

  if (process.env.NODE_ENV !== "development") {
    Sentry.captureException(error);
  }
}
