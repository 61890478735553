import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { omit } from "lodash";
import { paymentMethodActions } from "./actions";

const initialStates = {
  collections: {
    byId: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
      error: false,
    },
    update: {
      loading: false,
      loaded: false,
      error: false,
    },
    destroy: {
      loading: false,
      loaded: false,
      error: false,
    },
  },
};

const collections = handleActions(
  {
    [paymentMethodActions.list.success]: (state, action) => ({
      ...state,
      byId: action.payload.paymentMethods.reduce(
        (acc, paymentMethod) => ({
          ...acc,
          [paymentMethod.id]: paymentMethod,
        }),
        {},
      ),
    }),
    [paymentMethodActions.create.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.paymentMethod.id]: action.payload.paymentMethod,
      },
    }),
    [paymentMethodActions.update.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.paymentMethod.id]: action.payload.paymentMethod,
      },
    }),
    [paymentMethodActions.destroy.success]: (state, action) => ({
      ...state,
      byId: omit(state.byId, action.payload.id),
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [paymentMethodActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [paymentMethodActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true, error: false },
    }),
    [paymentMethodActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: true, error: true },
    }),
    [paymentMethodActions.create.request]: (state) => ({
      ...state,
      create: { loading: true, loaded: false },
    }),
    [paymentMethodActions.create.success]: (state) => ({
      ...state,
      create: { loading: false, loaded: true, error: false },
    }),
    [paymentMethodActions.create.error]: (state) => ({
      ...state,
      create: { loading: false, loaded: false, error: true },
    }),
    [paymentMethodActions.update.request]: (state) => ({
      ...state,
      update: { loading: true, loaded: false },
    }),
    [paymentMethodActions.update.success]: (state) => ({
      ...state,
      update: { loading: false, loaded: true, error: false },
    }),
    [paymentMethodActions.update.error]: (state) => ({
      ...state,
      update: { loading: false, loaded: false, error: true },
    }),
    [paymentMethodActions.destroy.request]: (state) => ({
      ...state,
      destroy: { loading: true, loaded: false },
    }),
    [paymentMethodActions.destroy.success]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: true, error: false },
    }),
    [paymentMethodActions.destroy.error]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
