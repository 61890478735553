import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const paymentMethodsApi = {
  list: async ({ organizationId }) => {
    const url = `/organizations/${organizationId}/payment-methods`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  create: async ({
    organizationId,
    name,
    organizationUserId,
    kind,
    lastDigits,
    accountName,
    paymentMethodAccountId,
  }) => {
    const url = `/organizations/${organizationId}/payment-methods`;
    const response = await backendService.post({
      url,
      data: fromCamelToSnake({
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        paymentMethodAccountId,
      }),
    });

    return { data: fromSnakeToCamel(response.data) };
  },

  update: async ({
    organizationId,
    id,
    name,
    organizationUserId,
    kind,
    lastDigits,
    accountName,
    paymentMethodAccountId,
  }) => {
    const url = `/organizations/${organizationId}/payment-methods/${id}`;
    const response = await backendService.patch({
      url,
      data: fromCamelToSnake({
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        paymentMethodAccountId,
      }),
    });

    return { data: fromSnakeToCamel(response.data) };
  },

  destroy: async ({ organizationId, id }) => {
    const url = `/organizations/${organizationId}/payment-methods/${id}`;
    const response = await backendService.destroy({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};
