import { createAction } from "redux-actions";

const namespaces = {
  user: "USER",
};

export const userActions = {
  updateMe: {
    request: createAction(`${namespaces.user}/UPDATE-ME/REQUEST`),
    success: createAction(`${namespaces.user}/UPDATE-ME/SUCCESS`),
    error: createAction(`${namespaces.user}/UPDATE-ME/ERROR`),
  },
  submitOnboarding: {
    request: createAction(`${namespaces.user}/SUBMIT-ONBOARDING/REQUEST`),
    success: createAction(`${namespaces.user}/SUBMIT-ONBOARDING/SUCCESS`),
    error: createAction(`${namespaces.user}/SUBMIT-ONBOARDING/ERROR`),
  },
  updateOnboarding: {
    request: createAction(`${namespaces.user}/UPDATE-ONBOARDING/REQUEST`),
    success: createAction(`${namespaces.user}/UPDATE-ONBOARDING/SUCCESS`),
    error: createAction(`${namespaces.user}/UPDATE-ONBOARDING/ERROR`),
  },
};
