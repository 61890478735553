import { call, put } from "redux-saga/effects";
import { organizationWebhookActions } from "./actions";
import { organizationWebhooksApi } from "./api";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const organizationWebhookSagas = {
  *list({ payload = {} }) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), onCompletion = () => {} } =
        payload;
      const response = yield call(organizationWebhooksApi.list, { organizationId });
      yield put(organizationWebhookActions.list.success(response.data));

      onCompletion(response.data);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(organizationWebhookActions.list.error(e));
    }
  },

  *create({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        url,
        enabled,
        onCompletion = () => {},
      } = payload;

      const response = yield call(organizationWebhooksApi.create, {
        organizationId,
        url,
        enabled,
      });

      yield put(organizationWebhookActions.create.success(response.data));
      onCompletion(response.data.hashTag);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(organizationWebhookActions.create.error(e));
    }
  },

  *update({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        url,
        enabled,
        onCompletion = () => {},
      } = payload;

      const response = yield call(organizationWebhooksApi.update, {
        organizationId,
        id,
        url,
        enabled,
      });

      yield put(organizationWebhookActions.update.success(response.data));
      onCompletion(response.data.hashTag);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(organizationWebhookActions.update.error(e));
    }
  },

  *destroy({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
      } = payload;

      const response = yield call(organizationWebhooksApi.destroy, {
        organizationId,
        id,
      });
      yield put(organizationWebhookActions.destroy.success(response.data));
      onCompletion(response.data);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(organizationWebhookActions.destroy.error(e));
    }
  },
};
