import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { taxRateActions } from "./actions";

const initialStates = {
  collections: {
    taxRates: {
      all: [],
    },
    accountingTaxCodes: {
      byId: {},
    },
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
    listAccountingTaxCodes: {
      loading: false,
      loaded: false,
    },
    create: {
      loading: false,
      loaded: false,
    },
    update: {
      loading: false,
      loaded: false,
    },
    destroy: {
      loading: false,
      loaded: false,
    },
  },
};

const addingOrReplacingTaxRate = (taxRates, newTaxRate) => {
  const itemIndex = taxRates.findIndex((c) => c.id === newTaxRate.id);

  if (itemIndex === -1) {
    // If the item does not exist, append it to the end
    return [...taxRates, newTaxRate];
  }

  // Otherwise, replace the existing item while preserving order
  return [...taxRates.slice(0, itemIndex), newTaxRate, ...taxRates.slice(itemIndex + 1)];
};

const collections = handleActions(
  {
    [taxRateActions.list.success]: (state, action) => ({
      ...state,
      taxRates: {
        ...state.taxRates,
        all: action.payload.taxRates,
      },
    }),
    [taxRateActions.listAccountingTaxCodes.success]: (state, action) => ({
      ...state,
      accountingTaxCodes: {
        byId: action.payload.accountingTaxCodes.reduce(
          (acc, accountingTaxCode) => ({
            ...acc,
            [accountingTaxCode.id]: accountingTaxCode,
          }),
          {},
        ),
      },
    }),
    [taxRateActions.create.success]: (state, action) => ({
      ...state,
      taxRates: {
        ...state.taxRates,
        all: addingOrReplacingTaxRate(state.taxRates.all, action.payload.taxRate),
      },
    }),
    [taxRateActions.update.success]: (state, action) => ({
      ...state,
      taxRates: {
        ...state.taxRates,
        all: addingOrReplacingTaxRate(state.taxRates.all, action.payload.taxRate),
      },
    }),
    [taxRateActions.destroy.success]: (state, action) => ({
      ...state,
      taxRates: {
        ...state.taxRates,
        all: state.taxRates.all.filter((taxRate) => taxRate.id !== action.payload.id),
      },
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [taxRateActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: state.loaded },
    }),
    [taxRateActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [taxRateActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
    [taxRateActions.listAccountingTaxCodes.request]: (state) => ({
      ...state,
      listAccountingTaxCodes: { loading: true, loaded: false },
    }),
    [taxRateActions.listAccountingTaxCodes.success]: (state) => ({
      ...state,
      listAccountingTaxCodes: { loading: false, loaded: true },
    }),
    [taxRateActions.listAccountingTaxCodes.error]: (state) => ({
      ...state,
      listAccountingTaxCodes: { loading: false, loaded: false, error: true },
    }),
    [taxRateActions.create.request]: (state) => ({
      ...state,
      create: { loading: true, loaded: false },
    }),
    [taxRateActions.create.success]: (state) => ({
      ...state,
      create: { loading: false, loaded: true },
    }),
    [taxRateActions.create.error]: (state) => ({
      ...state,
      create: { loading: false, loaded: false, error: true },
    }),
    [taxRateActions.update.request]: (state) => ({
      ...state,
      update: { loading: true, loaded: false },
    }),
    [taxRateActions.update.success]: (state) => ({
      ...state,
      update: { loading: false, loaded: true },
    }),
    [taxRateActions.update.error]: (state) => ({
      ...state,
      update: { loading: false, loaded: false, error: true },
    }),
    [taxRateActions.destroy.request]: (state) => ({
      ...state,
      destroy: { loading: true, loaded: false },
    }),
    [taxRateActions.destroy.success]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: true },
    }),
    [taxRateActions.destroy.error]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
