import { createAction } from "redux-actions";

const namespaces = {
  organizations: "ORGANIZATIONS",
};

export const organizationActions = {
  initTimeZone: {
    request: createAction(`${namespaces.organizations}/INIT-TIMEZONE/REQUEST`),
    success: createAction(`${namespaces.organizations}/INIT-TIMEZONE/SUCCESS`),
    error: createAction(`${namespaces.organizations}/INIT-TIMEZONE/ERROR`),
  },
  getCurrentSubscription: {
    request: createAction(`${namespaces.organizations}/GET-CURRENT-SUBSCRIPTION/REQUEST`),
    success: createAction(`${namespaces.organizations}/GET-CURRENT-SUBSCRIPTION/SUCCESS`),
    error: createAction(`${namespaces.organizations}/GET-CURRENT-SUBSCRIPTION/ERROR`),
  },
  users: {
    list: {
      request: createAction(`${namespaces.organizations}/USERS/LIST/REQUEST`),
      success: createAction(`${namespaces.organizations}/USERS/LIST/SUCCESS`),
      error: createAction(`${namespaces.organizations}/USERS/LIST/ERROR`),
    },
  },
  create: {
    request: createAction(`${namespaces.organizations}/CREATE/REQUEST`),
    success: createAction(`${namespaces.organizations}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.organizations}/CREATE/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.organizations}/UPDATE/REQUEST`),
    success: createAction(`${namespaces.organizations}/UPDATE/SUCCESS`),
    error: createAction(`${namespaces.organizations}/UPDATE/ERROR`),
  },
};
