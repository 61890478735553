import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import { uniqueId } from "lodash";
import ModalView from "../../modal-view/ModalView";
import Button from "../../button/Button";

import ButtonGroup from "../../button/button-group/ButtonGroup";

import "./pro-feature-modal.scss";

const ProFeatureModal = ({ onCleanup, messageType = "pro_feature" }) => {
  const { t } = useTranslation("components");
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <ModalView
      className="pro-feature-modal"
      isOpen={modalOpen}
      allowDismiss={true}
      onDismiss={() => {
        setModalOpen(false);
      }}
      onModalHideComplete={() => {
        onCleanup();
      }}
      allowBackgroundScroll={true}
    >
      <h1>{t("proFeatureModal.title")}</h1>

      <p>
        {messageType === "pro_feature"
          ? t("proFeatureModal.proOnlyFeature")
          : t("proFeatureModal.quotaDepleted")}
      </p>

      <div className="pro-feature-content">
        <h2>{t("proFeatureModal.subtitle")}</h2>
        <ul className="pro-features-list">
          <li>
            <Trans
              ns="components"
              i18nKey={"proFeatureModal.unlimitedDocuments"}
              components={{ strong: <strong /> }}
            />
          </li>
          <li>
            <Trans
              ns="components"
              i18nKey={"proFeatureModal.inviteUsers"}
              components={{ strong: <strong /> }}
            />
          </li>
          <li>
            <Trans
              ns="components"
              i18nKey={"proFeatureModal.multipleAccounts"}
              components={{ strong: <strong /> }}
            />
          </li>
        </ul>
      </div>

      <div className="actions">
        <ButtonGroup align="right">
          <Button
            theme="subtle"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {t("proFeatureModal.cancel")}
          </Button>
          <Button
            onClick={() => {
              window.location.href = "/dashboard/organization/subscription";
            }}
          >
            {t("proFeatureModal.getPro")}
          </Button>
        </ButtonGroup>
      </div>
    </ModalView>
  );
};

ProFeatureModal.propTypes = {
  onCleanup: PropTypes.func,
  messageType: PropTypes.oneOf(["pro_feature", "quota_depleted"]),
};

export function showProFeatureModal({ messageType = "pro_feature" } = {}) {
  const containerId = `pro-feature-modal-${uniqueId()}`;
  const container = document.createElement("div");
  container.setAttribute("id", containerId);
  const child = (
    <ProFeatureModal
      messageType={messageType}
      onCleanup={() => {
        container.remove();
      }}
    />
  );

  const root = createRoot(container);

  root.render(child);

  document.getElementById("overlay-container").appendChild(container);
}

export default ProFeatureModal;
