import { get } from "lodash";
import { useSelector } from "react-redux";
import { get as getToken, isActive as isTokenActive, remove as removeToken } from "../tokenService";

export const useLoginInfo = () => useSelector((state) => state.login.collections.loginInfo);

export const useCurrentUser = () => {
  const loginInfo = useLoginInfo();
  return get(loginInfo, "user", null);
};

export const useUserPreferredCurrencyOrUSD = () => {
  const loginInfo = useLoginInfo();
  return get(loginInfo, "user.preferredCurrency", "USD");
};

export const useCurrentOrganization = () =>
  useSelector((state) => state.login.collections.loginInfo.currentOrganization);

export const useHasPaidSubscription = () => useCurrentOrganization().hasPaidSubscription;

export const hasQuotaLeft = () => {};

export const isAuthenticated = () => {
  const token = getToken();

  if (token && isTokenActive(token)) {
    return true;
  }

  removeToken();
  return false;
};

export const useOrganizationTimeZone = () => {
  const organization = useCurrentOrganization();
  return get(organization, "timeZone", "UTC");
};
