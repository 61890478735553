import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { omit } from "lodash";
import { hashTagActions } from "./actions";
import { documentActions } from "../documents/actions";

const initialStates = {
  collections: {
    byId: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
    update: {
      loading: false,
      loaded: false,
    },
    destroy: {
      loading: false,
      loaded: false,
    },
  },
};

const updatingHashTags = (state, hashTags) => {
  if (!hashTags) {
    return { ...state };
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      ...hashTags.reduce(
        (acc, hashTag) => ({
          ...acc,
          [hashTag.id]: hashTag,
        }),
        {},
      ),
    },
  };
};

const collections = handleActions(
  {
    [hashTagActions.list.success]: (state, action) => ({
      ...state,
      byId: action.payload.hashTags.reduce(
        (acc, hashTag) => ({
          ...acc,
          [hashTag.id]: hashTag,
        }),
        {},
      ),
    }),
    [hashTagActions.update.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.hashTag.id]: action.payload.hashTag,
      },
    }),
    [hashTagActions.destroy.success]: (state, action) => ({
      ...state,
      byId: omit(state.byId, action.payload.id),
    }),
    [documentActions.update.success]: (state, action) => {
      const { hashTags } = action.payload.document;
      return updatingHashTags(state, hashTags);
    },
    [documentActions.updateMultiple.success]: (state, action) => {
      const { documents } = action.payload;
      const hashTags = documents.reduce((acc, document) => [...acc, ...document.hashTags], []);

      return updatingHashTags(state, hashTags);
    },
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [hashTagActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [hashTagActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [hashTagActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
    [hashTagActions.update.request]: (state) => ({
      ...state,
      update: { loading: true, loaded: false },
    }),
    [hashTagActions.update.success]: (state) => ({
      ...state,
      update: { loading: false, loaded: true },
    }),
    [hashTagActions.update.error]: (state) => ({
      ...state,
      update: { loading: false, loaded: false, error: true },
    }),
    [hashTagActions.destroy.request]: (state) => ({
      ...state,
      destroy: { loading: true, loaded: false },
    }),
    [hashTagActions.destroy.success]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: true },
    }),
    [hashTagActions.destroy.error]: (state) => ({
      ...state,
      destroy: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
