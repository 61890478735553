import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createReduxHistoryContext } from "redux-first-history";
import createSagaMiddleware from "redux-saga";
import { createWouterHook } from "redux-first-history/wouter";
import { createBrowserHistory } from "history";
import { configureReducers } from "./reducers";
import { sagas } from "./sagas";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: createBrowserHistory(),
  // other options if needed 
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  routerMiddleware,
  sagaMiddleware,
];

export const store = createStore(
  configureReducers({ router: routerReducer }),
  composeWithDevTools(
    applyMiddleware(...middlewares),
  ),
);

sagaMiddleware.run(sagas);

export const history = createReduxHistory(store);

// if you use wouter
export const wouterUseLocation = createWouterHook(history);
