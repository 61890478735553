import { call, put } from "redux-saga/effects";
import * as api from "./api";
import { accountableActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const accountableSagas = {
  *list(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        integrationKind = null,
        transactionType = null,
        onPageLoaded = () => {},
        onCompletion = () => {},
      } = action.payload || {};

      const accountables = [];
      let page = 0;
      let totalPages = 1;

      while (page <= totalPages) {
        page += 1;

        const response = yield call(api.list, {
          organizationId,
          integrationKind,
          transactionType,
          page: 1,
        });

        totalPages = response.data.totalPages;
        accountables.push(...response.data.accountables);

        onPageLoaded(response.data);
      }

      yield put(
        accountableActions.list.success({
          accountables,
          integrationKind,
          transactionType,
        }),
      );

      onCompletion(accountables);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountableActions.list.error(error));
    }
  },
  *create(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        name,
        transactionType,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.create, { organizationId, name, transactionType });
      yield put(accountableActions.create.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountableActions.create.error(error));
    }
  },
  *update(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        name,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.update, { organizationId, id, name });
      yield put(accountableActions.update.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountableActions.update.error(error));
    }
  },
  *destroy(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.destroy, { organizationId, id });
      yield put(accountableActions.destroy.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountableActions.destroy.error(error));
    }
  },
};
