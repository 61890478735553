/* eslint-disable max-len */
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import React, { Suspense } from "react";
import loadable from "@loadable/component";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import ScrollToTop from "./ScrollToTop.jsx";
import DelayedFallback from "./DelayedFallback.jsx";

const PurchaseProPaywallView = loadable(() =>
  import("../views/dashboard/onboarding/paywall/PurchaseProPaywallView.jsx"),
);
const OnboardingPurchaseSuccess = loadable(() =>
  import("../views/dashboard/onboarding/purchase-success/OnboardingPurchaseSuccess.jsx"),
);

// const IntroView = loadable(() => import("../views/intro/IntroView.jsx"));
const LoginView = loadable(() => import("../views/login/LoginView"));
const AcceptInviteView = loadable(() => import("../views/login/accept-invite/AcceptInviteView"));
const DashboardOnboardingView = loadable(() =>
  import("../views/dashboard/onboarding/DashboardOnboardingView"),
);
const DownloadMobileAppView = loadable(() =>
  import("../views/dashboard/onboarding/mobile/DownloadMobileAppView"),
);
const ForgotPasswordView = loadable(() =>
  import("../views/login/forgot-password/ForgotPasswordView"),
);
const ResetPasswordView = loadable(() => import("../views/login/reset-password/ResetPasswordView"));
const VerifyEmailView = loadable(() => import("../views/login/verify-email/VerifyEmailView"));
const RegisterView = loadable(() => import("../views/register/RegisterView.jsx"));
const RedeemAppSumoCodesView = loadable(() =>
  import("../views/appsumo/RedeemAppSumoCodesView.jsx"),
);
const DashboardView = loadable(() => import("../views/dashboard/shared/DashboardView"));
const DashboardDocumentsView = loadable(() =>
  import("../views/dashboard/documents/DashboardDocumentsView"),
);
const TokenExchangeView = loadable(() => import("../views/login-link/TokenExchangeView.jsx"));
const ReportBuilderView = loadable(() =>
  import("../views/dashboard/documents/report-builder/ReportBuilderView.jsx"),
);
const CreateReportView = loadable(() =>
  import("../views/dashboard/documents/report-builder/create-report/CreateReportView.jsx"),
);
const DocumentMassUploadView = loadable(() =>
  import("../views/dashboard/documents/mass-upload/DocumentMassUploadView.jsx"),
);
const DocumentMassUploadTaskView = loadable(() =>
  import("../views/dashboard/documents/mass-upload/task/DocumentMassUploadTaskView.jsx"),
);
const MileageReporterView = loadable(() =>
  import("../views/dashboard/mileage-reporter/MileageReporterView.jsx"),
);
const DashboardCreateDocumentView = loadable(() =>
  import("../views/dashboard/documents/create/DashboardCreateDocumentView"),
);
const DashboardEditDocumentView = loadable(() =>
  import("../views/dashboard/documents/edit/DashboardEditDocumentView"),
);
const DocumentsSearchView = loadable(() =>
  import("../views/dashboard/documents/search/DocumentsSearchView.jsx"),
);
const DashboardAccountsView = loadable(() =>
  import("../views/dashboard/accounts/DashboardAccountsView"),
);
const ProfileView = loadable(() => import("../views/dashboard/profile/ProfileView"));
const DashboardHomeView = loadable(() => import("../views/dashboard/home/DashboardHomeView"));
const OrganizationSubscriptionView = loadable(() =>
  import("../views/dashboard/organization/subscription/OrganizationSubscriptionView"),
);
const OrganizationUsersView = loadable(() =>
  import("../views/dashboard/organization/users/OrganizationUsersView"),
);
const InboundEmailsView = loadable(() =>
  import("../views/dashboard/organization/inbound-emails/InboundEmailsView"),
);
const OrganizationItemCategoriesView = loadable(() =>
  import("../views/dashboard/organization/item-categories/OrganizationItemCategoriesView"),
);
const OrganizationHashTagsView = loadable(() =>
  import("../views/dashboard/organization/hash-tags/OrganizationHashTagsView"),
);
const OrganizationIntegrationsView = loadable(() =>
  import("../views/dashboard/organization/integrations/OrganizationIntegrationsView.jsx"),
);
const OrganizationWebhookEventLogView = loadable(() =>
  import(
    "../views/dashboard/organization/integrations/webhooks/event-log/OrganizationWebhookEventLogView.jsx"
  ),
);
const OrganizationPaymentMethodsView = loadable(() =>
  import("../views/dashboard/organization/payment-methods/OrganizationPaymentMethodsView.jsx"),
);
const OrganizationTaxRatesView = loadable(() =>
  import("../views/dashboard/organization/tax-rates/OrganizationTaxRatesView"),
);
const PaymentCheckoutView = loadable(() =>
  import("../views/dashboard/organization/payments/PaymentCheckoutView"),
);
const SharedDocumentsView = loadable(() => import("../views/shared-documents/SharedDocumentsView"));
const DocumentShareCodeView = loadable(() =>
  import("../views/shared-documents/document-share-code-view/DocumentShareCodeView.jsx"),
);
const BankStatementTransactionsView = loadable(() =>
  import(
    "../views/dashboard/documents/month/bank-statement-transactions/BankStatementTransactionsView.jsx"
  ),
);
const QuickbooksAuthRedirectView = loadable(() =>
  import(
    "../views/dashboard/organization/integrations/quickbooks/redirect/QuickbooksAuthRedirectView.jsx"
  ),
);
const AccountingIntegrationSettingsView = loadable(() =>
  import(
    "../views/dashboard/organization/integrations/quickbooks/settings/AccountingIntegrationSettingsView.jsx"
  ),
);
const DashboardSettingsView = loadable(() =>
  import("../views/dashboard/settings/DashboardSettingsView.jsx"),
);
const DocumentBrowserView = loadable(() =>
  import("../views/dashboard/documents/browser/DocumentBrowserView.jsx"),
);

function ApplicationRoutes() {
  const location = useLocation();

  if (location.pathname.includes("/login")) {
    [DashboardHomeView].forEach((c) => c.preload());
  }

  if (location.pathname.includes("/register")) {
    [
      DashboardOnboardingView,
      DashboardHomeView,
      DownloadMobileAppView,
      PurchaseProPaywallView,
    ].forEach((c) => c.preload());
  }

  return (
    <Suspense fallback={<DelayedFallback />}>
      <ScrollToTop>
        <Routes>
          {/* The Brevo verification did not work via DNS so this was needed. */}
          <Route
            path="/5547ba668dd9fe0ee80f7f51bdd0edc7.html"
            element={<div>Brevo verification HTML</div>}
          />
          <Route path="/login" element={<LoginView />} />
          <Route path="/login/linkedin" element={<LinkedInCallback />} />
          <Route path="/register" element={<RegisterView />} />
          <Route path="/invite/:code" element={<AcceptInviteView />} />
          <Route path="/forgot-password" element={<ForgotPasswordView />} />
          <Route path="/verify-email/:code" element={<VerifyEmailView />} />
          <Route path="/reset-password/:userPayload/:token" element={<ResetPasswordView />} />
          <Route
            path="/documents/shared-one/:documentId/:shareCode"
            element={<DocumentShareCodeView />}
          />
          <Route
            path="/documents/shared/:documentShareLinkId/:shareCode"
            element={<SharedDocumentsView />}
          />
          <Route path="/appsumo-onboarding" element={<RedeemAppSumoCodesView />} />
          <Route path="/login-link/:token" element={<TokenExchangeView />} />
          <Route path="/dashboard/onboarding" element={<DashboardOnboardingView />} />
          <Route path="/dashboard/onboarding-offer" element={<PurchaseProPaywallView />} />
          <Route
            path="/dashboard/onboarding-purchase-success"
            element={<OnboardingPurchaseSuccess />}
          />
          <Route path="/dashboard/download-mobile-app" element={<DownloadMobileAppView />} />
          <Route
            path="/dashboard/organization/users"
            element={<DashboardView component={<OrganizationUsersView />} />}
          />
          <Route
            path="/dashboard/organization/subscription"
            element={<DashboardView component={<OrganizationSubscriptionView />} />}
          />
          <Route
            path="/dashboard/organization/inbound-emails"
            element={<DashboardView component={<InboundEmailsView />} />}
          />
          <Route
            path="/dashboard/organization/item-categories"
            element={<DashboardView component={<OrganizationItemCategoriesView />} />}
          />
          <Route
            path="/dashboard/organization/hash-tags"
            element={<DashboardView component={<OrganizationHashTagsView />} />}
          />
          <Route
            path="/dashboard/organization/payment-methods"
            element={<DashboardView component={<OrganizationPaymentMethodsView />} />}
          />
          <Route
            path="/dashboard/organization/integrations/webhooks/event-log"
            element={<DashboardView component={<OrganizationWebhookEventLogView />} />}
          />
          <Route
            path="/dashboard/organization/integrations/quickbooks/auth-redirect"
            element={<DashboardView component={<QuickbooksAuthRedirectView />} />}
          />
          <Route
            path="/dashboard/organization/integrations/quickbooks/settings"
            element={<DashboardView component={<AccountingIntegrationSettingsView />} />}
          />
          <Route
            path="/dashboard/organization/integrations"
            element={<DashboardView component={<OrganizationIntegrationsView />} />}
          />
          <Route
            path="/dashboard/organization/tax-rates"
            element={<DashboardView component={<OrganizationTaxRatesView />} />}
          />
          <Route
            path="/dashboard/organization/payments/checkout"
            element={<DashboardView component={<PaymentCheckoutView />} />}
          />
          <Route
            path="/dashboard/accounts"
            element={<DashboardView component={<DashboardAccountsView />} />}
          />
          <Route
            path="/dashboard/profile"
            element={<DashboardView component={<ProfileView />} />}
          />
          <Route
            path="/dashboard/documents/edit/:id"
            element={<DashboardView component={<DashboardEditDocumentView />} />}
          />
          <Route
            path="/dashboard/documents/bank-statements/:id/transactions"
            element={<DashboardView component={<BankStatementTransactionsView />} />}
          />
          <Route
            path="/dashboard/documents/create"
            element={<DashboardView component={<DashboardCreateDocumentView />} />}
          />
          <Route
            path="/dashboard/documents/recent"
            element={<DashboardView component={<DashboardDocumentsView />} />}
          />
          <Route
            path="/dashboard/documents/trash"
            element={<DashboardView component={<DashboardDocumentsView />} />}
          />
          <Route
            path="/dashboard/documents/search"
            element={<DashboardView component={<DocumentsSearchView />} />}
          />
          <Route
            path="/dashboard/documents/report-builder/create"
            element={<DashboardView component={<CreateReportView />} />}
          />
          <Route
            path="/dashboard/documents/mass-upload"
            element={<DashboardView component={<DocumentMassUploadView />} />}
          />
          <Route
            path="/dashboard/documents/browser"
            element={<DashboardView component={<DocumentBrowserView />} />}
          />
          <Route
            path="/dashboard/documents/mass-upload/tasks/:taskId"
            element={<DashboardView component={<DocumentMassUploadTaskView />} />}
          />
          <Route
            path="/dashboard/documents/report-builder"
            element={<DashboardView component={<ReportBuilderView />} />}
          />
          <Route
            path="/dashboard/documents/mileage-reports"
            element={<DashboardView component={<MileageReporterView />} />}
          />
          <Route
            path="/dashboard/documents/all-months"
            element={<DashboardView component={<DashboardDocumentsView />} />}
          />
          <Route
            path="/dashboard/documents/month/:month"
            element={<DashboardView component={<DashboardDocumentsView />} />}
          />
          <Route
            path="/dashboard/documents/category/:documentCategoryId"
            element={<DashboardView component={<DashboardDocumentsView />} />}
          />
          <Route
            path="/dashboard/settings"
            element={<DashboardView component={<DashboardSettingsView />} />}
          />
          <Route path="/dashboard" element={<DashboardView component={<DashboardHomeView />} />} />
          <Route path="/dashboard/*" element={<Navigate to="/dashboard" />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </ScrollToTop>
    </Suspense>
  );
}

export default ApplicationRoutes;
