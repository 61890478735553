import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { get } from "lodash";

import { organizationActions } from "../organization/actions";
import { userActions } from "../users/actions";

import * as actions from "./actions";
import { accountingIntegrationActions } from "../integrations/accounting/actions";

const initialStates = {
  collections: {
    loginInfo: {
      user: null,
      organizations: null,
      currentOrganization: null,
    },
  },
  status: {
    session: {
      loggingIn: false,
      loginRequest: "submit", // submit | submitting | success | invalid_credentials | trial_expired
      loginError: null,
    },
    loginInfo: {
      loading: false,
      loaded: false,
    },
  },
};

const collections = handleActions(
  {
    [actions.session.loginInfo.success]: (state, action) => ({
      ...state,
      loginInfo: {
        ...action.payload,
      },
    }),
    [userActions.updateMe.success]: (state, action) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        user: action.payload.user,
      },
    }),
    [userActions.submitOnboarding.request]: (state) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        user: { ...state.loginInfo.user, onboardingCompleted: true },
      },
    }),
    [userActions.submitOnboarding.success]: (state, action) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        user: action.payload.user,
      },
    }),
    [actions.session.organization.pick]: (state, action) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        currentOrganization: state.loginInfo.organizations.find(
          (o) => o.id === action.payload.organizationId,
        ),
      },
    }),
    [organizationActions.create.success]: (state, action) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        organizations: [
          ...state.loginInfo.organizations,
          { ...action.payload.organization, organizationUser: action.payload.organizationUser },
        ],
      },
    }),
    [organizationActions.update.success]: (state, action) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        organizations: state.loginInfo.organizations.map((organization) => {
          if (organization.id !== action.payload.organization.id) {
            return organization;
          }
          return { ...organization, name: action.payload.organization.name };
        }),
        currentOrganization:
          state.loginInfo.currentOrganization.id === action.payload.organization.id
            ? { ...state.loginInfo.currentOrganization, name: action.payload.organization.name }
            : state.loginInfo.currentOrganization,
      },
    }),
    [accountingIntegrationActions.updateSettings.success]: (state, action) => {
      const newState = { ...state, loginInfo: { ...state.loginInfo } };

      const newOrganizations = newState.loginInfo.organizations.map((organization) => {
        const accountingIntegrationId = get(
          organization,
          "integrations.organizationAccountingIntegration.id",
          null,
        );

        if (
          accountingIntegrationId === null ||
          accountingIntegrationId !== action.payload.organizationAccountingIntegration.id
        ) {
          return organization;
        }

        return {
          ...organization,
          integrations: {
            ...organization.integrations,
            organizationAccountingIntegration: action.payload.organizationAccountingIntegration,
          },
        };
      });

      newState.loginInfo.organizations = newOrganizations;
      newState.loginInfo.currentOrganization = newOrganizations.find(
        (o) => o.id === newState.loginInfo.currentOrganization.id,
      );

      return newState;
    },
  },
  initialStates.collections,
);

const status = handleActions(
  {
    // Login info
    [actions.session.loginInfo.request]: (state) => ({ ...state, loginInfo: { loading: true } }),
    [actions.session.loginInfo.success]: (state) => ({
      ...state,
      loginInfo: { loading: false, loaded: true },
    }),
    [actions.session.loginInfo.error]: (state) => ({ ...state, loginInfo: { loading: false } }),

    // Login
    [actions.session.login.request]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: true, loginRequest: "submitting" },
    }),
    [actions.session.login.success]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, loginRequest: "success" },
    }),
    [actions.session.login.error]: (state, action) => ({
      ...state,
      session: {
        ...state.session,
        loggingIn: false,
        loginRequest: action.payload.code,
      },
    }),

    // Login OAuth
    [actions.session.login.oauth.request]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: true, loginRequest: "submitting" },
    }),
    [actions.session.login.oauth.success]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, loginRequest: "success" },
    }),
    [actions.session.login.oauth.error]: (state, action) => ({
      ...state,
      session: {
        ...state.session,
        loggingIn: false,
        loginRequest: "failed",
        loginError: action.payload,
      },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
