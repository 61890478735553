import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { createRoot } from "react-dom/client";
import Helmet from "react-helmet";
import { uniqueId } from "lodash";

import { store } from "../../store";

import "./modal-view.scss";

const ModalView = ({
  isOpen,
  className = "",
  allowBackgroundScroll = false,
  requiresBodyScrollAdjust = false, // This is either needed or not depending on the modal position in the DOM
  allowDismiss = true,
  disableAnimation = false,
  animationType = "popIn", // "popInSubtle"
  usePortalToOverlay = true,
  children,
  onDismiss = () => {},
  onModalHideComplete = () => {},
  onModalAppearComplete = () => {},
  style = {},
}) => {
  const isOpenRef = useRef(isOpen);
  const [bodyScrollY, setBodyScrollY] = useState(0);

  const overlayContainer = document.getElementById("overlay-container");

  useEffect(() => {
    // This is disgusting but needed for the closure magic on onAnimationComplete...
    isOpenRef.current = isOpen;

    if (!allowBackgroundScroll) {
      if (isOpen) {
        if (requiresBodyScrollAdjust) {
          setBodyScrollY(window.scrollY);
        }
      } else {
        if (requiresBodyScrollAdjust) {
          requestAnimationFrame(() => {
            window.scrollTo(0, bodyScrollY);
          });
        }
        setBodyScrollY(0);
      }
    }
  }, [isOpen]);

  if (!overlayContainer) {
    return null;
  }

  const content = (
    <>
      <Helmet>
        {!allowBackgroundScroll && isOpen && (
          <html style={`overflow: hidden; position: relative; top: -${bodyScrollY}px`}></html>
        )}
      </Helmet>
      <AnimatePresence initial={!disableAnimation}>
        {isOpen && (
          <motion.div
            className={`modal-view ${className}-overlay`}
            initial={{ opacity: disableAnimation ? 1 : 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0 }}
            onAnimationComplete={() => {
              if (isOpenRef.current) {
                onModalAppearComplete();
              } else {
                onModalHideComplete();
              }
            }}
            onClick={(e) => {
              if (!allowDismiss) {
                return;
              }
              e.preventDefault();
              e.stopPropagation();
              onDismiss();
            }}
          >
            <div
              className={`modal-view-content animation-${animationType} ${className} ${
                disableAnimation ? "no-animation" : ""
              }`}
              style={style}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );

  if (!usePortalToOverlay) {
    return content;
  }

  return ReactDOM.createPortal(content, overlayContainer);
};

export function showCustomModalDialog({ id = null, children, modalProps = {} } = {}) {
  const containerId = id || `custom-modal-dialog-${uniqueId()}`;
  const container = document.createElement("div");
  container.setAttribute("id", containerId);

  const onCleanup = () => {
    container.remove();
  };

  const child = (
    <Provider store={store}>
      <ModalView
        isOpen={true}
        allowDismiss={true}
        allowBackgroundScroll={true}
        usePortalToOverlay={false}
        onDismiss={onCleanup}
        {...modalProps}
      >
        {children({ dismiss: onCleanup })}
      </ModalView>
    </Provider>
  );

  const root = createRoot(container);
  root.render(child);

  document.getElementById("overlay-container").appendChild(container);
}

ModalView.propTypes = {
  isOpen: PropTypes.bool,
  allowBackgroundScroll: PropTypes.bool,
  requiresBodyScrollAdjust: PropTypes.bool,
  allowDismiss: PropTypes.bool,
  disableAnimation: PropTypes.bool,
  animationType: PropTypes.oneOf(["popIn", "popInSubtle"]),
  onDismiss: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  usePortalToOverlay: PropTypes.bool,
  style: PropTypes.object,
  onModalHideComplete: PropTypes.func,
  onModalAppearComplete: PropTypes.func,
};

export default ModalView;
