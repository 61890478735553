import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const list = async ({
  organizationId,
  integrationKind,
  transactionType,
  pageSize = 500,
  page = 1,
}) => {
  const url = `/organizations/${organizationId}/accountables`;
  const response = await backendService.get({
    url,
    data: fromCamelToSnake({ integrationKind, transactionType, pageSize, page }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const create = async ({ organizationId, name, transactionType }) => {
  const url = `/organizations/${organizationId}/accountables`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ name, transactionType }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const update = async ({ organizationId, id, name, transactionType }) => {
  const url = `/organizations/${organizationId}/accountables/${id}`;
  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({ name, transactionType }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroy = async ({ organizationId, id }) => {
  const url = `/organizations/${organizationId}/accountables/${id}`;
  const response = await backendService.destroy({ url });

  return { data: fromSnakeToCamel(response.data) };
};
