// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6IFdXzrkHq36emQm8Uh-9jy19lo4Ywro",
  authDomain: "sparkreceipt.firebaseapp.com",
  projectId: "sparkreceipt",
  storageBucket: "sparkreceipt.appspot.com",
  messagingSenderId: "961391535983",
  appId: "1:961391535983:web:40a53e0ac4755191946a9a",
  measurementId: "G-F22197D2LF",
};

// Initialize Firebase

const Firebase = {
  initialized: false,
  app: null,
  analytics: null,
};

export function initializeFirebase() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  Firebase.app = app;
  Firebase.analytics = analytics;
}

export default Firebase;
