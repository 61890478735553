class RequestQueue {
  constructor(maxConcurrent) {
    this.maxConcurrent = maxConcurrent;
    this.currentRequests = 0;
    this.queue = [];
  }

  // Add request to the queue
  async enqueue(config, resolve) {
    this.queue.push({ config, resolve });
    this.processQueue();
  }

  async dequeue(config) {
    this.queue = this.queue.filter((item) => item.config.id !== config.id);
    this.currentRequests -= 1;
    this.processQueue();
  }

  // Process the queue if under the limit
  async processQueue() {
    while (this.currentRequests < this.maxConcurrent && this.queue.length > 0) {
      // Dequeue the next request
      const { config, resolve } = this.queue.shift();

      this.currentRequests += 1;

      resolve(config);

      // Move to the next item in the queue if available
      this.processQueue();
    }
  }
}

export default RequestQueue;
