import { createAction } from "redux-actions";

const namespaces = {
  documentMassUploads: "DOCUMENT-MASS-UPLOADS",
};

export const documentMassUploadActions = {
  list: {
    request: createAction(`${namespaces.documentMassUploads}/LIST/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/LIST/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/LIST/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.documentMassUploads}/CREATE/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/CREATE/ERROR`),
  },
  uploadFile: {
    request: createAction(`${namespaces.documentMassUploads}/UPLOAD-FILE/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/UPLOAD-FILE/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/UPLOAD-FILE/ERROR`),
  },
  beginTask: {
    request: createAction(`${namespaces.documentMassUploads}/BEGIN-TASK/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/BEGIN-TASK/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/BEGIN-TASK/ERROR`),
  },
  prepareMassUploadTask: {
    request: createAction(`${namespaces.documentMassUploads}/CREATE-UPLOAD-AND-BEGIN-TASK/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/CREATE-UPLOAD-AND-BEGIN-TASK/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/CREATE-UPLOAD-AND-BEGIN-TASK/ERROR`),
  },
  monitorTask: {
    request: createAction(`${namespaces.documentMassUploads}/MONITOR-TASK/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/MONITOR-TASK/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/MONITOR-TASK/ERROR`),
  },
  get: {
    request: createAction(`${namespaces.documentMassUploads}/STATUS/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/STATUS/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/STATUS/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.documentMassUploads}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.documentMassUploads}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.documentMassUploads}/DESTROY/ERROR`),
  },
};
