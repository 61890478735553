import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { omit } from "lodash";
import { organizationAutomationActions } from "./actions";

const initialStates = {
  collections: {
    recurringDocumentAutomations: {
      byId: {},
    },
    documentAssignAutomations: {
      byId: {},
    },
  },
  status: {
    list: {
      loading: false,
      loaded: false,
      error: false,
    },
  },
};

function updatingByIdCollection(state, automations, collectionName) {
  const newState = { ...state };

  automations.forEach((automation) => {
    newState[collectionName].byId[automation.id] = automation;
  });

  return newState;
}

const collections = handleActions(
  {
    // This is unnecessary
    // [organizationAutomationActions.list.success]: (state, action) => ({
    //   ...state,
    //   ...updatingByIdCollection(
    //     state,
    //     action.payload.documentAssignAutomations,
    //     "documentAssignAutomations",
    //   ),
    //   ...updatingByIdCollection(
    //     state,
    //     action.payload.recurringDocumentAutomations,
    //     "recurringDocumentAutomations",
    //   ),
    // }),
    [organizationAutomationActions.recurringDocumentAutomations.list.success]: (state, action) =>
      updatingByIdCollection(
        state,
        action.payload.recurringDocumentAutomations,
        "recurringDocumentAutomations",
      ),
    [organizationAutomationActions.recurringDocumentAutomations.get.success]: (state, action) =>
      updatingByIdCollection(
        state,
        [action.payload.recurringDocumentAutomation],
        "recurringDocumentAutomations",
      ),
    [organizationAutomationActions.documentAssignAutomations.list.success]: (state, action) =>
      updatingByIdCollection(
        state,
        action.payload.documentAssignAutomations,
        "documentAssignAutomations",
      ),
    [organizationAutomationActions.documentAssignAutomations.get.success]: (state, action) =>
      updatingByIdCollection(
        state,
        [action.payload.documentAssignAutomation],
        "documentAssignAutomations",
      ),
    [organizationAutomationActions.recurringDocumentAutomations.create.success]: (state, action) =>
      updatingByIdCollection(
        state,
        [action.payload.recurringDocumentAutomation],
        "recurringDocumentAutomations",
      ),
    [organizationAutomationActions.recurringDocumentAutomations.update.success]: (state, action) =>
      updatingByIdCollection(
        state,
        [action.payload.recurringDocumentAutomation],
        "recurringDocumentAutomations",
      ),
    [organizationAutomationActions.documentAssignAutomations.create.success]: (state, action) =>
      updatingByIdCollection(
        state,
        [action.payload.documentAssignAutomation],
        "documentAssignAutomations",
      ),
    [organizationAutomationActions.documentAssignAutomations.update.success]: (state, action) =>
      updatingByIdCollection(
        state,
        [action.payload.documentAssignAutomation],
        "documentAssignAutomations",
      ),
    [organizationAutomationActions.recurringDocumentAutomations.destroy.success]: (
      state,
      action,
    ) => ({
      ...state,
      recurringDocumentAutomations: {
        byId: omit(state.recurringDocumentAutomations.byId, action.payload.id),
      },
    }),
    [organizationAutomationActions.documentAssignAutomations.destroy.success]: (state, action) => ({
      ...state,
      documentAssignAutomations: {
        byId: omit(state.documentAssignAutomations.byId, action.payload.id),
      },
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [organizationAutomationActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [organizationAutomationActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [organizationAutomationActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
