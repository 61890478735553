import { createAction } from "redux-actions";

const namespaces = {
  organizationAutomations: "ORGANIZATION-AUTOMATIONS",
  recurringDocumentAutomations: "RECURRING-DOCUMENT-AUTOMATIONS",
  documentAssignAutomations: "DOCUMENT-ASSIGNMENT-AUTOMATIONS",
};

export const organizationAutomationActions = {
  list: {
    request: createAction(`${namespaces.organizationAutomations}/LIST/REQUEST`),
    success: createAction(`${namespaces.organizationAutomations}/LIST/SUCCESS`),
    error: createAction(`${namespaces.organizationAutomations}/LIST/ERROR`),
  },
  recurringDocumentAutomations: {
    get: {
      request: createAction(`${namespaces.recurringDocumentAutomations}/GET/REQUEST`),
      success: createAction(`${namespaces.recurringDocumentAutomations}/GET/SUCCESS`),
      error: createAction(`${namespaces.recurringDocumentAutomations}/GET/ERROR`),
    },
    list: {
      request: createAction(`${namespaces.recurringDocumentAutomations}/LIST/REQUEST`),
      success: createAction(`${namespaces.recurringDocumentAutomations}/LIST/SUCCESS`),
      error: createAction(`${namespaces.recurringDocumentAutomations}/LIST/ERROR`),
    },
    create: {
      request: createAction(`${namespaces.recurringDocumentAutomations}/CREATE/REQUEST`),
      success: createAction(`${namespaces.recurringDocumentAutomations}/CREATE/SUCCESS`),
      error: createAction(`${namespaces.recurringDocumentAutomations}/CREATE/ERROR`),
    },
    update: {
      request: createAction(`${namespaces.recurringDocumentAutomations}/PATCH/REQUEST`),
      success: createAction(`${namespaces.recurringDocumentAutomations}/PATCH/SUCCESS`),
      error: createAction(`${namespaces.recurringDocumentAutomations}/PATCH/ERROR`),
    },
    destroy: {
      request: createAction(`${namespaces.recurringDocumentAutomations}/DESTROY/REQUEST`),
      success: createAction(`${namespaces.recurringDocumentAutomations}/DESTROY/SUCCESS`),
      error: createAction(`${namespaces.recurringDocumentAutomations}/DESTROY/ERROR`),
    },
  },
  documentAssignAutomations: {
    get: {
      request: createAction(`${namespaces.documentAssignAutomations}/GET/REQUEST`),
      success: createAction(`${namespaces.documentAssignAutomations}/GET/SUCCESS`),
      error: createAction(`${namespaces.documentAssignAutomations}/GET/ERROR`),
    },
    list: {
      request: createAction(`${namespaces.documentAssignAutomations}/LIST/REQUEST`),
      success: createAction(`${namespaces.documentAssignAutomations}/LIST/SUCCESS`),
      error: createAction(`${namespaces.documentAssignAutomations}/LIST/ERROR`),
    },
    create: {
      request: createAction(`${namespaces.documentAssignAutomations}/CREATE/REQUEST`),
      success: createAction(`${namespaces.documentAssignAutomations}/CREATE/SUCCESS`),
      error: createAction(`${namespaces.documentAssignAutomations}/CREATE/ERROR`),
    },
    update: {
      request: createAction(`${namespaces.documentAssignAutomations}/PATCH/REQUEST`),
      success: createAction(`${namespaces.documentAssignAutomations}/PATCH/SUCCESS`),
      error: createAction(`${namespaces.documentAssignAutomations}/PATCH/ERROR`),
    },
    destroy: {
      request: createAction(`${namespaces.documentAssignAutomations}/DESTROY/REQUEST`),
      success: createAction(`${namespaces.documentAssignAutomations}/DESTROY/SUCCESS`),
      error: createAction(`${namespaces.documentAssignAutomations}/DESTROY/ERROR`),
    },
  },
};
