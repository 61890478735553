import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { paymentMethodAccountActions } from "./actions";

const initialStates = {
  collections: {
    byId: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
  },
};

const collections = handleActions(
  {
    [paymentMethodAccountActions.list.success]: (state, action) => ({
      ...state,
      byId: action.payload.paymentMethodAccounts.reduce(
        (acc, paymentMethodAccount) => ({
          ...acc,
          [paymentMethodAccount.id]: paymentMethodAccount,
        }),
        {},
      ),
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [paymentMethodAccountActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [paymentMethodAccountActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true },
    }),
    [paymentMethodAccountActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
