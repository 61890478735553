import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { transactionLineCollectionActions } from "./actions";
import { bankStatementActions } from "../bank-statements/actions";

const initialStates = {
  collections: {
    all: [],
  },
  status: {
    list: {
      loading: false,
      loaded: false,
    },
  },
};

function replacingCollection(all, collection) {
  if (!collection) {
    return [...all];
  }

  if (!all.find((c) => c.id === collection.id)) {
    return [...all, collection];
  }

  return all.map((c) => (c.id === collection.id ? collection : c));
}

function replacingTransactionLine(all, transactionLine) {
  if (!transactionLine) {
    return [...all];
  }

  return all.map((collection) => {
    if (collection.id === transactionLine.transactionLineCollectionId) {
      // Check if transactionLine already exists in the collection
      const existingIndex = collection.transactionLines.findIndex(
        (tl) => tl.id === transactionLine.id,
      );

      // Replace or add the transactionLine based on its existence
      return {
        ...collection,
        transactionLines:
          existingIndex >= 0
            ? collection.transactionLines.map((tl, index) =>
                index === existingIndex ? transactionLine : tl,
              )
            : [...collection.transactionLines, transactionLine],
      };
    }

    return collection;
  });
}

function replacingTransactionLines(all, transactionLines) {
  return all.map((collection) => ({
    ...collection,
    transactionLines: collection.transactionLines.map(
      (tl) => transactionLines.find((tl2) => tl2.id === tl.id) || tl,
    ),
  }));
}

function removingTransactionLines(all, transactionLineId) {
  return all.map((collection) => ({
    ...collection,
    transactionLines: collection.transactionLines.filter((tl) => tl.id !== transactionLineId),
  }));
}

const collections = handleActions(
  {
    [transactionLineCollectionActions.list.request]: (state) => ({
      ...state,
      all: [],
    }),
    [transactionLineCollectionActions.list.success]: (state, action) => ({
      ...state,
      all: action.payload.transactionLineCollections,
    }),
    [transactionLineCollectionActions.show.byId.success]: (state, action) => ({
      ...state,
      all: replacingCollection(state.all, action.payload.transactionLineCollection),
    }),
    [transactionLineCollectionActions.show.byDocumentId.success]: (state, action) => ({
      ...state,
      all: replacingCollection(state.all, action.payload.transactionLineCollection),
    }),
    [transactionLineCollectionActions.transactionLine.update.success]: (state, action) => ({
      ...state,
      all: replacingTransactionLine(state.all, action.payload.transactionLine),
    }),
    [transactionLineCollectionActions.transactionLine.updateMultiple.success]: (state, action) => ({
      ...state,
      all: replacingTransactionLines(state.all, action.payload.transactionLines),
    }),
    [transactionLineCollectionActions.transactionLine.destroy.success]: (state, action) => ({
      ...state,
      all: removingTransactionLines(state.all, action.payload.id),
    }),
    [transactionLineCollectionActions.destroy.success]: (state, action) => ({
      ...state,
      all: state.all.filter((c) => c.id !== action.payload.id),
    }),
    [transactionLineCollectionActions.transactionLine.convert.success]: (state, action) => ({
      ...state,
      all: replacingTransactionLine(state.all, action.payload.transactionLine),
    }),
    [transactionLineCollectionActions.transactionLine.convertMultiple.success]: (
      state,
      action,
    ) => ({
      ...state,
      all: replacingTransactionLines(
        state.all,
        action.payload.map((res) => res.transactionLine),
      ),
    }),
    [bankStatementActions.extract.status.success]: (state, action) => ({
      ...state,
      all: replacingCollection(state.all, action.payload.task.transactionLineCollection),
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [transactionLineCollectionActions.list.request]: (state) => ({
      ...state,
      list: { loading: true, loaded: false },
    }),
    [transactionLineCollectionActions.list.success]: (state) => ({
      ...state,
      list: { loading: false, loaded: true, error: false },
    }),
    [transactionLineCollectionActions.list.error]: (state) => ({
      ...state,
      list: { loading: false, loaded: false, error: true },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
