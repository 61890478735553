import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const updateMe = async ({
  name,
  preferredCurrency,
  preferredTaxMode,
  webLocale,
  webLanguage,
  preferredDateFormat,
  preferredDateFormats,
  marketingConsent,
  enabledNotifications,
}) => {
  const url = "/users/me";

  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({
      name,
      preferredCurrency,
      preferredTaxMode,
      webLocale,
      webLanguage,
      preferredDateFormat,
      preferredDateFormats,
      marketingConsent,
      enabledNotifications,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const submitOnboarding = async (params) => {
  const url = "/users/me/onboarding";

  const response = await backendService.post({
    url,
    data: fromCamelToSnake(params),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const updateOnboarding = async (params) => {
  const url = "/users/me/onboarding/update";

  const response = await backendService.patch({
    url,
    data: fromCamelToSnake(params),
  });

  return { data: fromSnakeToCamel(response.data) };
};
