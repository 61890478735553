import { call, delay, put, select, take } from "redux-saga/effects";
import { get } from "lodash";
import * as api from "./api";
import { documentExportActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const documentExportSagas = {
  *list(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload || {};

      const response = yield call(api.listDocumentExports, {
        organizationId,
      });

      yield put(documentExportActions.list.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentExportActions.list.error(error));
    }
  },
  *create(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        filter,
        format,
        config = {},
        name,
        pollStatusUpdates = true,
        onCompletion = () => {},
        onExportCompleted = () => {},
      } = action.payload;
      const response = yield call(api.exportDocuments, {
        organizationId,
        filter,
        config,
        format,
        name,
      });
      yield put(documentExportActions.create.success(response.data.documentExport));
      onCompletion(response.data.documentExport);

      if (!pollStatusUpdates) {
        return;
      }

      // Poll until completion
      let completed = false;
      while (!completed) {
        yield delay(3000);
        yield put(
          documentExportActions.status.request({
            documentExportId: response.data.documentExport.id,
          }),
        );
        yield take([documentExportActions.status.success, documentExportActions.status.error]);

        const documentExport = yield select((state) =>
          get(state, `documentExports.collections.byId[${response.data.documentExport.id}]`, null),
        );

        completed = get(documentExport, "status") === "completed";

        if (completed) {
          onExportCompleted(documentExport);
        }
      }
    } catch (error) {
      debugPrintAndLogError(error);
      documentExportActions.create.error(error);
      const { onError = () => {} } = action.payload;
      onError({ error });
    }
  },
  *status(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentExportId,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.exportDocumentsStatus, {
        organizationId,
        documentExportId,
      });

      yield put(documentExportActions.status.success(response.data));

      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentExportActions.status.error(error));
    }
  },
  *destroy(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentExportId,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.destroyDocumentExport, {
        organizationId,
        documentExportId,
      });

      yield put(documentExportActions.destroy.success(response.data));

      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(
        documentExportActions.destroy.error({
          error,
          documentExportId: action.payload.documentExportId,
        }),
      );
    }
  },
};
