import { createAction } from "redux-actions";

const namespaces = {
  itemCategories: "ITEM-CATEGORIES",
};

export const itemCategoryActions = {
  list: {
    clear: createAction(`${namespaces.itemCategories}/LIST/CLEAR`),
    request: createAction(`${namespaces.itemCategories}/LIST/REQUEST`),
    success: createAction(`${namespaces.itemCategories}/LIST/SUCCESS`),
    error: createAction(`${namespaces.itemCategories}/LIST/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.itemCategories}/CREATE/REQUEST`),
    success: createAction(`${namespaces.itemCategories}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.itemCategories}/CREATE/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.itemCategories}/UPDATE/REQUEST`),
    success: createAction(`${namespaces.itemCategories}/UPDATE/SUCCESS`),
    error: createAction(`${namespaces.itemCategories}/UPDATE/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.itemCategories}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.itemCategories}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.itemCategories}/DESTROY/ERROR`),
  },
};
