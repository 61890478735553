import { createAction } from "redux-actions";

const namespaces = {
  organizationWebhooks: "ORGANIZATION-WEBHOOKS",
};

export const organizationWebhookActions = {
  list: {
    request: createAction(`${namespaces.organizationWebhooks}/LIST/REQUEST`),
    success: createAction(`${namespaces.organizationWebhooks}/LIST/SUCCESS`),
    error: createAction(`${namespaces.organizationWebhooks}/LIST/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.organizationWebhooks}/CREATE/REQUEST`),
    success: createAction(`${namespaces.organizationWebhooks}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.organizationWebhooks}/CREATE/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.organizationWebhooks}/PATCH/REQUEST`),
    success: createAction(`${namespaces.organizationWebhooks}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.organizationWebhooks}/PATCH/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.organizationWebhooks}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.organizationWebhooks}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.organizationWebhooks}/DESTROY/ERROR`),
  },
};
