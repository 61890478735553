import React from "react";
import PropTypes from "prop-types";

import "./button-group.scss";

const ButtonGroup = ({ children, align = "left" }) => (
  <div className={`button-group align-${align}`}>
    { children }
  </div>
);

ButtonGroup.propTypes = {
  children: PropTypes.any,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default ButtonGroup;
