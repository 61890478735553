import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { accountingIntegrationActions } from "./actions";

const initialStates = {
  collections: {
    tasks: {
      byId: {},
    },
    provisionTasks: {
      byId: {},
    },
    validations: {
      byId: {
        // [documentId]: { ...validation },
      },
    },
  },
};

const updatingTasks = (state, action) => {
  const newState = {
    ...state,
    tasks: {
      byId: { ...state.tasks.byId },
    },
  };

  // Remove all tasks targeting the same document ids
  action.payload.tasks.forEach((task) => {
    Object.values(newState.tasks.byId).forEach((t) => {
      if (t.documentId === task.documentId && t.updatedAt < task.updatedAt) {
        delete newState.tasks.byId[t.id];
      }
    });
  });

  // Add the new tasks
  action.payload.tasks.forEach((task) => {
    const existingTask = newState.tasks.byId[task.id];

    if (!existingTask || existingTask.updatedAt <= task.updatedAt) {
      newState.tasks.byId[task.id] = task;
    }
  });

  return newState;
};

const collections = handleActions(
  {
    [accountingIntegrationActions.validatePublishDocuments.success]: (state, action) => {
      const newState = {
        ...state,
        validations: {
          byId: { ...state.validations.byId },
        },
      };

      action.payload.validations.forEach((validation) => {
        newState.validations.byId[validation.documentId] = validation;
      });

      return newState;
    },
    [accountingIntegrationActions.publishDocuments.success]: (state, action) =>
      updatingTasks(state, action),
    [accountingIntegrationActions.publishDocumentStatus.success]: (state, action) =>
      updatingTasks(state, action),
    [accountingIntegrationActions.syncAccountingIntegrationData.queued]: (state, action) => ({
      ...state,
      provisionTasks: {
        byId: { ...state.provisionTasks.byId, [action.payload.task.id]: action.payload.task },
      },
    }),
    [accountingIntegrationActions.syncAccountingIntegrationStatus.success]: (state, action) => {
      const newState = {
        ...state,
        provisionTasks: {
          byId: { ...state.provisionTasks.byId },
        },
      };

      if (action.payload.task) {
        newState.provisionTasks.byId[action.payload.task.id] = action.payload.task;
      }

      return newState;
    },
  },
  initialStates.collections,
);

export const reducer = combineReducers({
  collections,
});
