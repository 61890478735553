import { createAction } from "redux-actions";

const namespaces = {
  appConfigurations: "APP-CONFIGURATIONS",
};

export const appConfigurationActions = {
  get: {
    request: createAction(`${namespaces.appConfigurations}/GET/REQUEST`),
    success: createAction(`${namespaces.appConfigurations}/GET/SUCCESS`),
    error: createAction(`${namespaces.appConfigurations}/GET/ERROR`),
  },
};
