import * as backendService from "../../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../../utils/letterCasingUtils";

export const getAuthUrl = async ({ organizationId, platform }) => {
  const url = `/integrations/accounting/auth-uri`;

  const response = await backendService.get({
    url,
    data: fromCamelToSnake({ organizationId, platform }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const provisionAccountingAuthCode = async ({
  organizationId,
  code,
  platform,
  externalCompanyId,
}) => {
  const url = `/integrations/accounting/provision-auth-code`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ organizationId, code, externalCompanyId, platform }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const syncAccountingIntegrationData = async ({ organizationId, platform }) => {
  const url = `/integrations/accounting/sync-data`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      organizationId,
      platform,
      syncItems: [
        "preferences",
        "categories",
        "accountables",
        "payment_method_accounts",
        "tax_codes",
      ],
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const getProvisionAccountingIntegrationStatus = async ({
  organizationId,
  platform,
  taskId = null, // Optional
}) => {
  const url = `/integrations/accounting/provision-status`;

  const response = await backendService.get({
    url,
    data: fromCamelToSnake({ organizationId, platform, taskId }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const updateAccountingIntegrationSettings = async ({
  organizationId,
  platform,
  defaultPaymentMethodAccountId = null,
  alwaysConvertToHomeCurrency = null,
}) => {
  const url = `/integrations/accounting/settings`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      organizationId,
      platform,
      defaultPaymentMethodAccountId,
      alwaysConvertToHomeCurrency,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const validatePublishDocumentsToAccounting = async ({
  organizationId,
  documentIds,
  platform,
}) => {
  const url = `/integrations/accounting/publish-documents/validate`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ organizationId, documentIds, platform }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const publishDocumentsToAccounting = async ({
  organizationId,
  documentIds,
  platform,
  paymentMethodAccountId,
  updateAlreadyPublished = false,
  publishWithWarnings = false,
}) => {
  const url = `/integrations/accounting/publish-documents`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      organizationId,
      documentIds,
      platform,
      paymentMethodAccountId,
      updateAlreadyPublished,
      publishWithWarnings,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const getPublishDocumentsStatus = async ({ organizationId, taskIds }) => {
  const url = `/integrations/accounting/publish-documents-status`;

  const response = await backendService.get({
    url,
    data: fromCamelToSnake({ organizationId, taskIds }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const getPublishDocumentsStatusByDocumentIds = async ({ organizationId, documentIds }) => {
  const url = `/integrations/accounting/publish-documents-status/by-document-ids`;

  const response = await backendService.get({
    url,
    data: fromCamelToSnake({ organizationId, documentIds }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const revokeAccountingIntegration = async ({ organizationId, platform }) => {
  const url = `/integrations/accounting/revoke`;

  const response = await backendService.post({
    url,
    data: fromCamelToSnake({ organizationId, platform }),
  });

  return { data: fromSnakeToCamel(response.data) };
};
