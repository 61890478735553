import { fromSnakeToCamel } from "../../utils/letterCasingUtils";
import * as backendService from "../backendService";

export const LocalesApi = {
  async list() {
    const url = "/locales";

    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  async listTimeZoneToRegionMapping() {
    const url = "/locales/time-zones-to-region";

    const response = await backendService.get({ url });

    // Note: fromSnakeToCamel is not wanted here, as the response contains time zone keys
    // that are partially in snake_case and should remain that way
    return {
      data: {
        timeZoneToRegionMap: response.data.time_zone_to_region_map,
      },
    };
  },
};
