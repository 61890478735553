import { createAction } from "redux-actions";

const namespaces = {
  paymentMethods: "PAYMENT-METHODS",
};

export const paymentMethodActions = {
  list: {
    request: createAction(`${namespaces.paymentMethods}/LIST/REQUEST`),
    success: createAction(`${namespaces.paymentMethods}/LIST/SUCCESS`),
    error: createAction(`${namespaces.paymentMethods}/LIST/ERROR`),
  },
  create: {
    request: createAction(`${namespaces.paymentMethods}/CREATE/REQUEST`),
    success: createAction(`${namespaces.paymentMethods}/CREATE/SUCCESS`),
    error: createAction(`${namespaces.paymentMethods}/CREATE/ERROR`),
  },
  update: {
    request: createAction(`${namespaces.paymentMethods}/PATCH/REQUEST`),
    success: createAction(`${namespaces.paymentMethods}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.paymentMethods}/PATCH/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.paymentMethods}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.paymentMethods}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.paymentMethods}/DESTROY/ERROR`),
  },
};
