import { createAction } from "redux-actions";

const namespaces = {
  transactionLineCollections: "TRANSACTION-LINE-COLLECTIONS",
};

export const transactionLineCollectionActions = {
  list: {
    request: createAction(`${namespaces.transactionLineCollections}/LIST/REQUEST`),
    success: createAction(`${namespaces.transactionLineCollections}/LIST/SUCCESS`),
    error: createAction(`${namespaces.transactionLineCollections}/LIST/ERROR`),
  },
  show: {
    byId: {
      request: createAction(`${namespaces.transactionLineCollections}/SHOW/BY-ID/REQUEST`),
      success: createAction(`${namespaces.transactionLineCollections}/SHOW/BY-ID/SUCCESS`),
      error: createAction(`${namespaces.transactionLineCollections}/SHOW/BY-ID/ERROR`),
    },
    byDocumentId: {
      request: createAction(`${namespaces.transactionLineCollections}/SHOW/BY-DOCUMENT-ID/REQUEST`),
      success: createAction(`${namespaces.transactionLineCollections}/SHOW/BY-DOCUMENT-ID/SUCCESS`),
      error: createAction(`${namespaces.transactionLineCollections}/SHOW/BY-DOCUMENT-ID/ERROR`),
    },
  },
  destroy: {
    request: createAction(`${namespaces.transactionLineCollections}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.transactionLineCollections}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.transactionLineCollections}/DESTROY/ERROR`),
  },
  transactionLine: {
    update: {
      request: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/UPDATE/REQUEST`,
      ),
      success: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/UPDATE/SUCCESS`,
      ),
      error: createAction(`${namespaces.transactionLineCollections}/TRANSACTION-LINE/UPDATE/ERROR`),
    },
    updateMultiple: {
      request: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/UPDATE-MULTIPLE/REQUEST`,
      ),
      success: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/UPDATE-MULTIPLE/SUCCESS`,
      ),
      error: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/UPDATE-MULTIPLE/ERROR`,
      ),
    },
    destroy: {
      request: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/DESTROY/REQUEST`,
      ),
      success: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/DESTROY/SUCCESS`,
      ),
      error: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/DESTROY/ERROR`,
      ),
    },
    convert: {
      request: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/CONVERT/REQUEST`,
      ),
      success: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/CONVERT/SUCCESS`,
      ),
      error: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/CONVERT/ERROR`,
      ),
    },
    convertMultiple: {
      request: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/CONVERT-MULTIPLE/REQUEST`,
      ),
      success: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/CONVERT-MULTIPLE/SUCCESS`,
      ),
      error: createAction(
        `${namespaces.transactionLineCollections}/TRANSACTION-LINE/CONVERT-MULTIPLE/ERROR`,
      ),
    },
  },
};
