import * as backendService from "../backendService";
import { fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const paymentMethodAccountsApi = {
  list: async ({ organizationId }) => {
    const url = `/organizations/${organizationId}/payment-method-accounts`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};
