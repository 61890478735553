import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const hashTagsApi = {
  list: async ({ organizationId }) => {
    const url = `/organizations/${organizationId}/hash-tags`;
    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },

  update: async ({ organizationId, id, name }) => {
    const url = `/organizations/${organizationId}/hash-tags/${id}`;
    const response = await backendService.patch({ url, data: fromCamelToSnake({ name }) });

    return { data: fromSnakeToCamel(response.data) };
  },

  destroy: async ({ organizationId, id }) => {
    const url = `/organizations/${organizationId}/hash-tags/${id}`;
    const response = await backendService.destroy({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};
