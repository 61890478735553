import { call, put } from "redux-saga/effects";
import { paymentMethodActions } from "./actions";
import { paymentMethodsApi } from "./api";
import { useCurrentOrganizationId, withRetry } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const paymentMethodSagas = {
  *list({ payload = {} }) {
    yield withRetry(
      function* listSaga() {
        const { organizationId = yield useCurrentOrganizationId(), onCompletion = () => {} } =
          payload;
        const response = yield call(paymentMethodsApi.list, { organizationId });
        yield put(paymentMethodActions.list.success(response.data));

        onCompletion(response.data);
      },
      {
        *onError(e) {
          debugPrintAndLogError(e);
          yield put(paymentMethodActions.list.error(e));
        },
      },
    );
  },

  *create({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        paymentMethodAccountId,
        onCompletion = () => {},
      } = payload;

      const response = yield call(paymentMethodsApi.create, {
        organizationId,
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        paymentMethodAccountId,
      });

      yield put(paymentMethodActions.create.success(response.data));
      onCompletion(response.data.hashTag);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(paymentMethodActions.create.error(e));

      if (payload.onError) {
        payload.onError(e);
      }
    }
  },

  *update({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        paymentMethodAccountId,
        onCompletion = () => {},
      } = payload;

      const response = yield call(paymentMethodsApi.update, {
        organizationId,
        id,
        name,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
        paymentMethodAccountId,
      });

      yield put(paymentMethodActions.update.success(response.data));
      onCompletion(response.data.hashTag);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(paymentMethodActions.update.error(e));

      if (payload.onError) {
        payload.onError(e);
      }
    }
  },

  *destroy({ payload = {} }) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        id,
        onCompletion = () => {},
        organizationUserId,
        kind,
        lastDigits,
        accountName,
      } = payload;

      const response = yield call(paymentMethodsApi.destroy, {
        organizationId,
        id,
        organizationUserId,
        kind,
        lastDigits,
        accountName,
      });
      yield put(paymentMethodActions.destroy.success(response.data));
      onCompletion(response.data);
    } catch (e) {
      debugPrintAndLogError(e);
      yield put(paymentMethodActions.destroy.error(e));

      if (payload.onError) {
        payload.onError(e);
      }
    }
  },
};
