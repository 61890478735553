import { combineReducers } from "redux";
import { keyBy, omit, uniqBy } from "lodash";
import { handleActions } from "redux-actions";

import { documentMassUploadActions } from "./actions";
import { documentActions } from "../documents/actions";

const initialStates = {
  collections: {
    byId: {},
  },
  status: {
    list: {
      loading: false,
      loaded: false,
      error: false,
    },
    create: {
      uploadingFiles: [],
      uploadedFiles: [],
      errorFiles: [],
    },
    deletingIds: [],
    deletedIds: [],
  },
};

const updatingDocuments = (state, documents) => {
  const newState = { ...state, byId: { ...state.byId } };

  documents.forEach((document) => {
    Object.values(newState.byId).some((dmut) => {
      const documentUploadTask = dmut.documentUploadTasks.find(
        (dut) => dut.documentId === document.id,
      );

      if (documentUploadTask) {
        documentUploadTask.document = document;

        newState.byId[dmut.id] = {
          ...dmut,
          documentUploadTasks: [...dmut.documentUploadTasks],
        };

        // Exit the loop early since we've found the task
        return true;
      }

      // Continue searching if not found
      return false;
    });
  });

  return newState;
};

const collections = handleActions(
  {
    [documentMassUploadActions.list.request]: (state) => ({
      ...state,
    }),
    [documentMassUploadActions.list.success]: (state, action) => ({
      ...state,
      byId: keyBy(action.payload.tasks, "id"),
    }),
    [documentMassUploadActions.destroy.success]: (state, action) => ({
      ...state,
      byId: omit(state.byId, [action.payload.id]),
    }),
    [documentMassUploadActions.create.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.task.id]: action.payload.task,
      },
    }),
    [documentMassUploadActions.get.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.task.id]: action.payload.task,
      },
    }),
    [documentMassUploadActions.beginTask.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.task.id]: action.payload.task,
      },
    }),
    [documentMassUploadActions.uploadFile.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.task.id]: {
          ...action.payload.task,
          documentUploadTasks: uniqBy(
            [
              ...(state.byId[action.payload.task.id]
                ? state.byId[action.payload.task.id].documentUploadTasks
                : []),
              ...action.payload.task.documentUploadTasks,
            ],
            "id",
          ),
        },
      },
    }),
    [documentActions.get.success]: (state, action) => ({
      ...updatingDocuments(state, [action.payload.document]),
    }),
    [documentActions.getList.success]: (state, action) => ({
      ...updatingDocuments(state, action.payload.documents),
    }),
    [documentActions.update.success]: (state, action) => ({
      ...updatingDocuments(state, [action.payload.document]),
    }),
    [documentActions.updateMultiple.success]: (state, action) => ({
      ...updatingDocuments(state, action.payload.documents),
    }),
    [documentActions.trash.success]: (state, action) => ({
      ...updatingDocuments(state, [action.payload.document]),
    }),
    [documentActions.restore.success]: (state, action) => ({
      ...updatingDocuments(state, [action.payload.document]),
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    [documentMassUploadActions.list.request]: (state) => ({
      ...state,
      list: { ...state.list, loading: true },
    }),
    [documentMassUploadActions.list.success]: (state) => ({
      ...state,
      list: { ...state.list, loading: false, loaded: true },
    }),
    [documentMassUploadActions.list.error]: (state) => ({
      ...state,
      list: { ...state.list, loading: false, loaded: false, error: true },
    }),
    [documentMassUploadActions.destroy.request]: (state, action) => ({
      ...state,
      deletingIds: [...state.deletingIds, action.payload.taskId],
    }),
    [documentMassUploadActions.destroy.success]: (state, action) => ({
      ...state,
      deletingIds: state.deletingIds.filter((id) => id !== action.payload.id),
      deletedIds: [...state.deletedIds, action.payload.id],
    }),
    [documentMassUploadActions.destroy.error]: (state, action) => ({
      ...state,
      deletingIds: state.deletingIds.filter((id) => id !== action.payload.taskId),
    }),
    [documentMassUploadActions.uploadFile.request]: (state, action) => ({
      ...state,
      create: {
        ...state.create,
        uploadingFiles: [...state.create.uploadingFiles, action.payload.file],
      },
    }),
    [documentMassUploadActions.uploadFile.success]: (state, action) => ({
      ...state,
      create: {
        ...state.create,
        uploadingFiles: state.create.uploadingFiles.filter((file) => file !== action.payload.file),
        uploadedFiles: [...state.create.uploadedFiles, action.payload.file],
      },
    }),
    [documentMassUploadActions.uploadFile.error]: (state, action) => ({
      ...state,
      create: {
        ...state.create,
        uploadingFiles: state.create.uploadingFiles.filter((file) => file !== action.payload.file),
        errorFiles: [...state.create.errorFiles, action.payload.file],
      },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
